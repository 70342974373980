<template>
  <v-app>
    <v-container fluid fill-height style="background-color:white">
      <v-layout align-center justify-center>
        <AcceptInvite :invite_token="invite_token" />
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import AcceptInvite from "../../shared/components/AcceptInvite";

export default {
  components: {
    AcceptInvite
  },
  data() {
    let invite_token;
    if (this.$route.params.token) {
      invite_token = this.$route.params.token;
    } else {
      invite_token = "";
    }
    return {
      invite_token: invite_token
    };
  }
};
</script>

<style>
</style>