<template>
  <v-app>
    <div
      v-if="global_metronome"
      style="position: fixed; bottom: 40px; left: 10px; width: 400px; z-index: 5000; box-shadow: 4px 4px 3px grey; border: 1px solid grey; border-radius: 5px"
    >
      <ToolMetronome ref="global_metronome" smaller @close="close" />
    </div>
  </v-app>
</template>

<script>
import ToolMetronome from "./components/ToolMetronome.vue";
import jQuery from "jquery";

export default {
  name: "GlobalMetronome",
  components: {
    ToolMetronome,
  },
  data() {
    return {
      global_metronome: false,
    };
  },
  mounted() {
    window.OpticalGymGlobalMetronome = this;
  },
  methods: {
    close() {
      if (this.$refs["global_metronome"]) {
        this.$refs["global_metronome"].stop();
      }
      this.global_metronome = false;
      jQuery("#opticalgym_global_metronome_wrapper").hide();
    },
    open() {
      jQuery("#opticalgym_global_metronome_wrapper").show();
      this.global_metronome = true;
    },
  },
};
</script>
