<template>
  <div>
    <ClinicPage
      v-if="user_clinics.length == 1"
      :clinic_id="user_clinics[0].id"
      :current_user="current_user"
      :user_invites="user_invites"
    />
    <SelectClinic v-else-if="user_clinics.length > 1" />
    <AccountArchived v-else />
  </div>
</template>

<script>
import ClinicPage from "../../shared/components/ClinicPage";
import SelectClinic from "../../shared/components/SelectClinic";
import AccountArchived from "../../shared/components/AccountArchived";
import { mapState } from "vuex";

export default {
  components: { ClinicPage, SelectClinic, AccountArchived },
  data() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const admin = urlParams.get("admin");

    return {
      dont_redirect_admin: admin == 'true'
    };
  },
  computed: {
    ...mapState(["user_clinics", "current_user", "user_invites"]),
  },
  mounted() {
    if (this.current_user && this.current_user.is_admin && !this.dont_redirect_admin) {
      this.$router.push("/app/admin/clinics");
    }
  },
  methods: {
    navigate_to_clinic: function (clinic_id) {
      this.$router.push("/app/clinic/" + clinic_id);
    },
  },
};
</script>

<style></style>
