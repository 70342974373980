<template>
  <v-app>
    <div v-if="current_user.is_admin">
      <AdminMenuToolbar />
      <Locales />
    </div>
    <PermissionDenied v-else />
  </v-app>
</template>

<script>
import Locales from "../../shared/components/admin/Locales";
import PermissionDenied from "../../shared/components/PermissionDenied";
import AdminMenuToolbar from "./AdminMenuToolbar"

export default {
  components: {
    Locales,
    PermissionDenied,
    AdminMenuToolbar,
  },
  data() {
    return {
      current_user: this.$root.$children[0].current_user,
    };
  },
};
</script>

<style>
</style>