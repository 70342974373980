<template>
  <v-app>
    <div v-if="admin_or_staff">
        printables for {{ invite_id }}
    </div>
    <PermissionDenied v-else />
  </v-app>
</template>

<script>
import PermissionDenied from "../../shared/components/PermissionDenied";

export default {
  name: "App",
  components: {
    PermissionDenied,
  },
  data() {
    return {
      invite_id: this.$route.params.invite_id,
      current_user: this.$root.$children[0].current_user,
      user_invites: this.$root.$children[0].user_invites,
    };
  },
  computed: {
    admin_or_staff() {
      if (this.current_user.is_admin) {
        return true;
      }
      for (var i in this.user_invites) {
        let invite = this.user_invites[i];
        if (invite.user_role == "admin" || invite.user_role == "staff") {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style>
</style>