import RootRoute from "./routes/RootRoute";

import ExerciseDemoRoute from "./routes/ExerciseDemoRoute";
import VideoDemoRoute from "./routes/VideoDemoRoute";
import CommunityContentRoute from "./routes/CommunityContentRoute";
import CommunityContentClinicRoute from "./routes/CommunityContentClinicRoute";
import PasswordResetRoute from "./routes/PasswordRestRoute";
import AdminClinicsRoute from "./routes/AdminClinicsRoute";
import AdminUsersRoute from "./routes/AdminUsersRoute";
import AdminSharedRoute from "./routes/AdminSharedRoute";
import AdminChargesRoute from "./routes/AdminChargesRoute";
import AdminMonthlyReportsRoute from "./routes/AdminMonthlyReportsRoute";
import ClinicRoute from "./routes/ClinicRoute";
import ToolsRoute from "./routes/ToolsRoute";
import GridsDemoRoute from "./routes/GridsDemoRoute";
import ClinicSignUpRoute from "./routes/ClinicSignUpRoute";
import AcceptInviteRoute from "./routes/AcceptInviteRoute";
import EquipmentRoute from "./routes/EquipmentRoute";
import AdminClinicRoute from "./routes/AdminClinicRoute";
import AdminUserRoute from "./routes/AdminUserRoute";
import AdminRevisionsRoute from "./routes/AdminRevisionsRoute";
import AdminLocalesRoute from "./routes/AdminLocalesRoute";
import PrintReportRoute from "./routes/PrintReportRoute";
import PrintablesRoute from "./routes/PrintablesRoute";
import ReadingRoute from "./routes/ReadingRoute";
import BillingRoute from "./routes/BillingRoute";
import ClinicBilling from "../shared/components/ClinicBilling";
import ClinicGrids from "../shared/components/ClinicGrids";
import ProgressReportRoute from "./routes/ProgressReportRoute";

const routes = [
  { path: "/app", component: RootRoute },
  { path: "/app/exercises", component: ExerciseDemoRoute },
  { path: "/app/grids", component: GridsDemoRoute },
  { path: "/app/equipment", component: EquipmentRoute },
  { path: "/app/videos", component: VideoDemoRoute },
  { path: "/app/shared/clinic/:id", component: CommunityContentClinicRoute },
  { path: "/app/shared", component: CommunityContentRoute }, 
  { path: "/app/password_reset", component: PasswordResetRoute },
  { path: "/app/tools", component: ToolsRoute },
  { path: "/app/printables", component: PrintablesRoute },
  { path: "/app/reading", component: ReadingRoute },
  { path: "/app/progress_report", component: ProgressReportRoute },
  {
    path: "/app/clinic/:id", component: ClinicRoute, name: 'clinic_page', children: [
      {
        name: 'clinic_billing',
        path: 'billing',
        component: ClinicBilling,
      },
      {
        name: 'clinic_grids',
        path: 'grids',
        component: ClinicGrids,
      },
    ],
  },
  { path: "/app/clinic/:id/billing", component: ClinicRoute },
  { path: "/app/clinic/:id/grids", component: ClinicRoute },
  { path: "/app/billing", component: BillingRoute },
  { path: "/app/code/:token", component: AcceptInviteRoute },
  { path: "/app/code", component: AcceptInviteRoute },
  { path: "/app/signup", component: ClinicSignUpRoute },
  { path: "/app/print/progress_report/:invite_id", component: PrintReportRoute },
  { path: "/app/admin/clinics", component: AdminClinicsRoute },
  { path: "/app/admin/users", component: AdminUsersRoute },
  { path: "/app/admin/charges", component: AdminChargesRoute },
  { path: "/app/admin/revisions", component: AdminRevisionsRoute },
  { path: "/app/admin/monthly", component: AdminMonthlyReportsRoute },
  { path: "/app/admin/locales", component: AdminLocalesRoute },
  { path: "/app/admin/shared", component: AdminSharedRoute },
  { path: "/app/admin/clinic/:id", component: AdminClinicRoute },
  { path: "/app/admin/user/:id", component: AdminUserRoute },

];

export default routes;
