<template>
  <div>
    <v-list :dense="dense"> 
      <!-- Single Clinic -->
      <v-list-item v-if="clinics.length" link :to="'/app/clinic/' + current_clinic_id">
        <v-list-item-icon :class="$vuetify.rtl ? 'ml-6' : 'mr-6'">
          <v-icon>business</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("main_menu.my_clinic") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Exercises -->
      <v-list-item v-if="is_admin_or_staff" link to="/app/exercises">
        <v-list-item-icon :class="$vuetify.rtl ? 'ml-6' : 'mr-6'">
          <v-icon>category</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("common.exercises") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Grids -->
      <v-list-item v-if="is_admin_or_staff" link to="/app/grids">
        <v-list-item-icon :class="$vuetify.rtl ? 'ml-6' : 'mr-6'">
          <v-icon>fas fa-border-all</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("clinic_page.grids") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Printables -->
      <v-list-item v-if="allow_printables" link to="/app/printables">
        <v-list-item-icon :class="$vuetify.rtl ? 'ml-6' : 'mr-6'">
          <v-icon>print</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("common.printable_sheets") }}</v-list-item-title>
      </v-list-item>

      <!-- Reading Texts -->
      <v-list-item v-if="is_admin_or_staff" link to="/app/reading">
        <v-list-item-icon :class="$vuetify.rtl ? 'ml-6' : 'mr-6'">
          <v-icon>mdi-book-open-page-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("common.reading") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Progress Report -->
      <v-list-item v-if="allow_progress_report" link to="/app/progress_report">
        <v-list-item-icon :class="$vuetify.rtl ? 'ml-6' : 'mr-6'">
          <v-icon>fa-chart-bar</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("common.progress_report") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Community Sharing -->
      <v-list-item v-if="is_admin_or_staff && app_config.community_sharing" link to="/app/shared">
        <v-list-item-icon :class="$vuetify.rtl ? 'ml-6' : 'mr-6'">
          <v-icon>mdi-share-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title style="overflow: visible">{{ $t("common.community_content") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Tools -->
      <v-list-item v-if="allow_view_tools" link to="/app/tools">
        <v-list-item-icon :class="$vuetify.rtl ? 'ml-6' : 'mr-6'">
          <v-icon>build</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("common.tools") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Equipment -->
      <v-list-item v-if="is_admin_or_staff" link to="/app/equipment">
        <v-list-item-icon :class="$vuetify.rtl ? 'ml-6' : 'mr-6'">
          <v-icon>fas fa-glasses</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("common.equipment") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Admin Section -->
      <v-list-item v-if="user.is_admin" link to="/app/admin/clinics">
        <v-list-item-icon :class="$vuetify.rtl ? 'ml-6' : 'mr-6'">
          <v-icon>fa-cogs</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title><span class="hidden-sm-and-down">{{ $t("main_menu.admin") }}</span></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    clinics: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    let data = {
      calibration_dialog: false, // Are we showing the calibration dialog?
      account_dialog: false, // Are we showing the account dialog?
      dark_mode_toggle: this.$root.$children[0].$vuetify.theme.dark,
    };

    return data;
  },
  computed: {
    ...mapState(["app_config", "current_clinic_id", "current_clinic", "current_clinic_role", "user_regimens", "current_user"]),
    dense() {
      return this.$vuetify.breakpoint.height < 780;
    },
    limit_name_collection() {
      if (this.user.is_admin) {
        return false;
      }
      this.clinics.forEach((clinic) => {
        if (clinic.user_role != "user") return false;
      });
      return true;
    },
    is_admin_or_staff() {
      for (var i in this.clinics) {
        if (this.clinics[i].user_role == "admin" || this.clinics[i].user_role == "staff") return true;
      }
      if (this.user.is_admin) {
        return true;
      }
      return false;
    },
    allow_view_tools() {
      if (this.current_user && this.current_user.is_admin) {
        return true;
      }
      if (!this.current_clinic) {
        return false;
      }

      if (this.current_clinic_role == "admin" || this.current_clinic_role == "staff") {
        return true;
      }

      for (let regimen of this.user_regimens) {
        if (regimen.clinic_id == this.current_clinic.id && regimen.permissions.view_tools === true) {
          return true;
        }
        if (regimen.clinic_id == this.current_clinic.id && regimen.permissions.view_tools === false) {
          return false;
        }
      }

      if (this.current_clinic.has_tag("default_allow_view_tools")) {
        return this.current_clinic.tag_value("default_allow_view_tools");
      }

      return true;
    },
    allow_progress_report() {
      if (!this.current_clinic) {
        return false;
      }

      if (this.current_clinic_role == "user") {
        for (let regimen of this.user_regimens) {
          if (regimen.clinic_id == this.current_clinic.id && regimen.permissions.view_progress_report === true) {
            return true;
          }
          if (regimen.clinic_id == this.current_clinic.id && regimen.permissions.view_progress_report === false) {
            return false;
          }
        }

        if (this.current_clinic.has_tag("default_allow_progress_report")) {
          return this.current_clinic.tag_value("default_allow_progress_report");
        }
      }

      return false;
    },
    allow_printables() {
      if (this.current_user && this.current_user.is_admin) {
        return true;
      }
      if (!this.current_clinic) {
        return false;
      }

      if (this.current_clinic_role == "admin" || this.current_clinic_role == "staff") {
        return true;
      }

      for (let regimen of this.user_regimens) {
        if (regimen.clinic_id == this.current_clinic.id && regimen.permissions.view_printables === true) {
          return true;
        }
        if (regimen.clinic_id == this.current_clinic.id && regimen.permissions.view_printables === false) {
          return false;
        }
      }

      if (this.current_clinic.has_tag("default_allow_view_printables")) {
        return this.current_clinic.tag_value("default_allow_view_printables");
      }

      return true;
    },
    allow_reading_texts() {
      if (this.is_educational && this.clinic_role == "staff") {
        return false;
      }
      return this.clinic_role == "staff" || this.clinic_role == "admin";
    },
    allow_video_exercises() {
      if (this.is_educational && this.clinic_role == "staff") {
        return false;
      }
      return this.clinic_role == "staff" || this.clinic_role == "admin";
    },

  },

  methods: {
    toggle_dark_mode() {
      if (this.dark_mode_toggle) {
        this.dark_mode_toggle = false;
      } else {
        this.dark_mode_toggle = true;
      }
      this.dark_mode();
    },
    dark_mode() {
      messageBus.$emit("dark-mode", this.dark_mode_toggle);
    },
    open_calibration_dialog() {
      this.$store.commit("setCalibrationDialog", true);
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Poppins");

.ocudigital-wordmark {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  color: #4e89f7;
  font-weight: 700;
}

.ocudigital-wordmark .wordmark-beta {
  color: black;
  font-size: xx-small;
  position: relative;
  top: -12px;
}

.theme--dark .ocudigital-wordmark .wordmark-beta {
  color: white;
}

.dark_mode_toggle label {
  font-size: smaller;
}

.theme--light .main_menu_toolbar .v-btn:hover {
  background-color: #f5f5f5;
}
</style>