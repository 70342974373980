<template>
  <div class="video-demo-list">
    <v-app-bar
      flat
      app
      style="margin-top: 64px; border-bottom: 1px solid rgba(0, 0, 0, 0.2)"
    >
      <div>
        <v-radio-group
          v-model="filter_exercise_type"
          row
          class="mt-4"
          :dense="dense"
        >
          <template v-slot:label>
            <span v-if="!dense" style="font-size: larger">{{
              $t("common.show")
            }}</span>
          </template>
          <v-radio
            :label="$t('common.all')"
            value="all"
            :class="dense ? '' : ''"
          ></v-radio>
          <v-radio value="interactive" :class="dense ? '' : 'ml-2'">
            <template v-slot:label>
              <div :style="dense ? 'font-size: 0.9rem' : ''">
                <v-icon v-if="!dense" left small>category</v-icon
                >{{ $t("common.interactive") }}
              </div>
            </template>
          </v-radio>
          <v-radio value="video" :class="dense ? '' : 'ml-2'">
            <template v-slot:label>
              <div :style="dense ? 'font-size: 0.9rem' : ''">
                <v-icon v-if="!dense" left small>ondemand_video</v-icon
                >{{ $t("common.video") }}
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
      <v-spacer />

      <v-text-field
        v-model="filter"
        append-icon="mdi-magnify"
        :label="$t('common.search')"
        single-line
        hide-details
        outlined
        dense
        style="max-width: 500px"
      ></v-text-field>

      <v-spacer />

      <v-btn class="mr-1" color="primary" @click="open_create_video_dialog">{{
        $t("common.add_exercise")
      }}</v-btn>

      <v-dialog
        v-model="create_video_dialog"
        max-width="1200"
        persistent
        @click:outside="confim_close_create_video_dialog"
      >
        <VideoExerciseEdit
          v-if="create_video_dialog"
          ref="create_video_dialog"
          :video="new_video"
          :video_exercises="video_exercises"
          :clinic_title="(current_clinic && current_clinic.title) || ''"
          @close="close_create_video_dialog"
          @video-created="
            load_clinic_videos();
            load_videos();
          "
          @video-updated="
            load_clinic_videos();
            load_videos();
          "
        ></VideoExerciseEdit>
      </v-dialog>
    </v-app-bar>

    <div
      v-if="
        sidebar &&
        $vuetify.breakpoint.name != 'xs' &&
        $vuetify.breakpoint.name != 'sm'
      "
      class="category-list"
      style="position: fixed; top: 128px"
    >
      <v-navigation-drawer permanent :width="dense ? '220' : '256'">
        <v-list>
          <div v-for="group in sorted_exercises" :key="group.group">
            <v-divider
              v-if="group.group == 'Disabled'"
              class="mt-5 mb-5"
            ></v-divider>

            <v-list-item :href="group.anchor" class="video_list_jump_link">
              <v-list-item-action v-if="!dense">
                <v-icon>{{ icons[group.group] }}</v-icon>
              </v-list-item-action>
              <v-icon v-else :left="!$vuetify.rtl" :right="$vuetify.rtl" small>{{ icons[group.group] }}</v-icon>

              <v-list-item-content v-if="group.group">
                <v-list-item-title :style="dense ? 'font-size: 0.9rem;' : ''">{{
                  $t("group." + group.group)
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </v-navigation-drawer>
    </div>

    <div :style="mainlist_style">
      <div v-for="group in sorted_exercises" :key="group.group">
        <v-card style="border-radius: 0">
          <v-card-title primary-title>
            <h3
              :id="group.id"
              class="headline"
              style="
                padding-top: 140px;
                margin-top: -128px;
                padding-bottom: 4px;
                pointer-events: none;
              "
            >
              <v-icon left style="vertical-align: unset">{{
                icons[group.group]
              }}</v-icon>
              {{ $t("group." + group.group) }}
            </h3>
          </v-card-title>

          <div>
            <v-list>
              <div v-for="(item, index) in group.exercises" :key="index">
                <v-list-item
                  three-line
                  :style="
                    'cursor: pointer; background-color: ' +
                    item_background_color(index)
                  "
                  @click="open_exercise_dialog(item)"
                >
                  <v-list-item-avatar>
                    <v-icon v-if="item.exercise_type == 'interactive'"
                      >category</v-icon
                    >
                    <v-icon v-else-if="item.default && item.default.video"
                      >ondemand_video</v-icon
                    >
                    <v-icon v-else>subject</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      style="font-weight: 550"
                      :class="
                        video_selections[item.id] === false
                          ? 'font-weight-thin'
                          : ''
                      "
                    >
                      {{ exercise_title(item) }}
                      <v-chip
                        v-if="item.is_new"
                        small
                        label
                        color="light-green"
                        style="margin-left: 10px"
                        >New</v-chip
                      >
                    </v-list-item-title>
                    <v-list-item-subtitle
                      :class="
                        video_selections[item.id] === false
                          ? 'font-weight-thin'
                          : 'font-weight-regular'
                      "
                      >{{ exercise_description(item) }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                  <v-list-item-action style="width: 100px">
                    <div v-if="item.exercise_type == 'video/clinic'">
                      <v-chip
                        class="ma-2 mt-3"
                        style="width: 90px; cursor: pointer"
                        label
                        outlined
                        color="primary"
                        v-on="on"
                        @click.stop="open_video_dialog(item.id)"
                      >
                        <v-icon left x-small>fa fa-edit</v-icon
                        >{{ $t("common.edit") }}
                      </v-chip>

                      <v-dialog
                        v-model="edit_video_dialog[item.id]"
                        max-width="1200"
                        persistent
                        @click:outside="confim_close_edit_video_dialog(item.id)"
                      >
                        <VideoExerciseEdit
                          v-if="edit_video_dialog[item.id]"
                          ref="edit_video_dialog"
                          :video="clinic_video_exercises_by_id[item.id]"
                          :video_exercises="video_exercises"
                          :clinic_title="
                            (current_clinic && current_clinic.title) || ''
                          "
                          show_delete
                          @close="close_video_dialog(item.id)"
                          @video-updated="
                            load_clinic_videos();
                            load_videos();
                          "
                          @video-deleted="
                            close_video_dialog(item.id);
                            load_clinic_videos();
                            load_videos();
                          "
                        ></VideoExerciseEdit>
                      </v-dialog>
                    </div>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-chip
                      v-if="item.demo_only"
                      small
                      outlined
                      label
                      color="red"
                      class="mt-4"
                      >Admin Only</v-chip
                    >
                    <v-chip
                      v-if="item.is_new"
                      small
                      label
                      outlined
                      color="light-green"
                      class="mt-4"
                      >{{ $t("common.new") }}</v-chip
                    >
                    <v-tooltip v-if="item.experimental" top>
                      <template v-slot:activator="{ on }">
                        <v-chip
                          small
                          label
                          outlined
                          color="blue"
                          class="mt-4"
                          >{{ $t("common.beta") }}</v-chip
                        >
                      </template>
                      <span>{{ $t("exercise_config.beta_text") }}</span>
                    </v-tooltip>

                    <v-tooltip
                      v-if="item.origin == 'clinic' && item.shared"
                      top
                    >
                      <template v-slot:activator="{ on }">
                        <v-chip
                          class="ma-2 mt-4"
                          style="width: 90px"
                          small
                          close
                          label
                          outlined
                          close-icon="mdi-share-variant"
                          v-on="on"
                          >Shared</v-chip
                        >
                      </template>
                      <span>{{ $t("video_demo_list.shared_exercise") }}</span>
                    </v-tooltip>
                    <v-tooltip
                      v-else-if="
                        item.origin == 'clinic' &&
                        !item.shared &&
                        !current_clinic.has_tag('public_video_exercises')
                      "
                      top
                    >
                      <template v-slot:activator="{ on }">
                        <v-chip
                          class="ma-2 mt-4"
                          style="width: 90px"
                          small
                          label
                          outlined
                          v-on="on"
                        >
                          {{ $t("video_demo_list.private") }}
                          <v-icon right x-small style="margin-left: 15px"
                            >fa fa-user-lock</v-icon
                          ></v-chip
                        >
                      </template>
                      <span>{{ $t("video_demo_list.private_exercise") }}</span>
                    </v-tooltip>
                    <v-tooltip
                      v-else-if="
                        item.origin == 'clinic' &&
                        !item.shared &&
                        current_clinic.has_tag('public_video_exercises')
                      "
                      top
                    >
                      <template v-slot:activator="{ on }">
                        <v-chip
                          class="ma-2 mt-4"
                          style="width: 90px"
                          small
                          label
                          outlined
                          v-on="on"
                        >
                          {{ $t("video_demo_list.public") }}
                          <v-icon right x-small style="margin-left: 15px"
                            >fa fa-globe</v-icon
                          ></v-chip
                        >
                      </template>
                      <span>{{ $t("video_demo_list.public_exercise") }}</span>
                    </v-tooltip>
                    <v-tooltip
                      v-else-if="item.origin == 'public' || item.share_source"
                      top
                    >
                      <template v-slot:activator="{ on }">
                        <v-chip label small outlined class="mt-4" v-on="on">{{
                          $t("common.clinic_attribution", {
                            clinic_title: item.provider_name,
                          })
                        }}</v-chip>
                      </template>
                      <span>{{ $t("video_demo_list.public_exercise") }}</span>
                    </v-tooltip>

                    <v-chip
                      v-if="
                        current_clinic &&
                        current_clinic_selections &&
                        item.exercise_type == 'video/builtin'
                      "
                      class="ma-2 mt-4"
                      small
                      close
                      :color="
                        video_selections[item.id] === false ? 'gray' : 'green'
                      "
                      label
                      outlined
                      style="width: 90px"
                      :close-icon="
                        in_progress[item.id]
                          ? 'fas fa-spinner'
                          : video_selections[item.id] === false
                          ? 'mdi-circle-off-outline'
                          : 'mdi-check-circle-outline'
                      "
                      @click.stop="toggle_enabled(item)"
                      @click:close="toggle_enabled(item)"
                    >
                      {{
                        video_selections[item.id] === false
                          ? $t("common.disabled")
                          : $t("common.enabled")
                      }}
                    </v-chip>
                  </v-list-item-action>
                </v-list-item>
                <v-divider />
                <div v-if="item.exercise_type == 'interactive'">
                  <v-dialog v-model="dialogs[item.id]" max-width="1000">
                    <ExerciseConfig
                      v-if="dialogs[item.id]"
                      :key="item.exercise_id"
                      :exercise="item"
                      :show_advanced="show_advanced_config"
                      show_additional
                      show_preset
                      @close="close_dialog(item.id)"
                    />
                  </v-dialog>
                </div>
                <div v-else>
                  <v-dialog
                    v-model="dialogs[item.id]"
                    max-width="800"
                    class="video_exercise_dialog"
                    @click:outside="exercise_stop(item.id, index)"
                  >
                    <VideoExercise
                      v-if="dialogs[item.id]"
                      ref="VideoExercise"
                      :exercise="item"
                      show_config
                      :trusted="item.origin == 'builtin'"
                      @close="close_dialog(item.id, index)"
                      @exercise-finished="close_dialog(item.id, index)"
                    />
                  </v-dialog>
                </div>
              </div>
            </v-list>
          </div>
        </v-card>
      </div>
    </div>

    <NoResults
      v-if="filter && !filtered_exercises.length"
      :query="filter"
      class="mt-12"
    />

    <v-dialog v-model="require_calibration_dialog" max-width="400">
      <v-card v-if="require_calibration_dialog">
        <v-card-title class="headline">{{
          $t("common.calibration_is_required")
        }}</v-card-title>
        <v-card-text>{{
          $t("common.before_continue_we_need_to_calibrate_your_device")
        }}</v-card-text>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="open_calibration_dialog">{{
            $t("common.click_here_to_calibrate_your_device")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import $ from "jquery";
import VideoExercise from "./VideoExercise";
import ExerciseConfig from "./ExerciseConfig.vue";
import VideoExerciseEdit from "./VideoExerciseEdit.vue";
import NoResults from "./NoResults.vue";
import { mapState } from "vuex";
import { Selection } from "../classes/selection";
import { category_icons } from "../lib/category";

export default {
  components: { VideoExercise, ExerciseConfig, VideoExerciseEdit, NoResults },
  props: {
    sidebar: {
      type: Boolean,
      default: () => true,
    },
    show_advanced_config: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      filter: "",
      filter_exercise_type: "all",
      dialogs: {},
      require_calibration_dialog: false,
      icons: category_icons,
      enabled_disabed: {},
      toggled: [], // List of currenly toggled videos, so we don't recategorize them imminentely
      in_progress: {}, // List of in-progress toggles
      create_video_dialog: false,
      edit_video_dialog: {},
      new_video: {
        title: "",
        description: "",
        instructions: "",
        video: "",
        variant: "default",
        exercise_group: "",
        tools: [],
        clinic_id: this.current_clinic_id,
      },
      on: null,
    };
  },
  computed: {
    ...mapState([
      "current_user",
      "current_clinic_id",
      "current_clinic",
      "current_clinic_selections",
      "current_calibration",
      "video_exercises",
      "user_clinics",
      "current_clinic_role",
      "small_screen",
      "exercises",
      "clinic_video_exercises",
    ]),
    mainlist_style() {
      if (
        this.sidebar &&
        this.$vuetify.breakpoint.name != "xs" &&
        this.$vuetify.breakpoint.name != "sm"
      ) {
        const direction = this.$vuetify.rtl ? "right" : "left";
        const paddingDirection = this.$vuetify.rtl
          ? "padding-left"
          : "padding-right";
        return this.dense
          ? `position: relative; ${direction}: 220px; ${paddingDirection}: 230px`
          : `position: relative; ${direction}: 250px; ${paddingDirection}: 270px`;
      } else {
        return "";
      }
    },
    dense() {
      return (
        !this.$root.$children[0].drawer_mini &&
        this.$vuetify.breakpoint.width < 1200
      );
    },
    clinic_video_exercises_by_id() {
      let video_exercises = {};
      for (let video_exercise of this.clinic_video_exercises) {
        video_exercises[video_exercise.id] = video_exercise;
      }
      return video_exercises;
    },
    interactive_exercises() {
      return this.exercises;
    },
    filtered_exercises() {
      let exercises_array = [];

      if (
        this.filter_exercise_type == "all" ||
        this.filter_exercise_type == "video"
      ) {
        for (let id in this.video_exercises) {
          let exercise = this.video_exercises[id];
          exercise.exercise_type = "video/" + exercise.origin;
          if (exercise.deprecated) {
            continue;
          }
          exercises_array.push(exercise);
        }
      }

      if (
        this.filter_exercise_type == "all" ||
        this.filter_exercise_type == "interactive"
      ) {
        for (let id in this.interactive_exercises) {
          let exercise = this.interactive_exercises[id];
          exercise.exercise_type = "interactive";
          if (exercise.deprecated) {
            continue;
          }
          if (exercise.demo_only && !this.current_user.is_admin) {
            continue;
          }
          if (
            exercise.canary &&
            (!this.current_clinic || !this.current_clinic.has_tag("canary")) &&
            !this.current_user.is_admin
          ) {
            continue;
          }
          exercises_array.push(exercise);
        }
      }

      let filter = this.filter.trim().toLowerCase();
      if (!filter) {
        return exercises_array;
      }

      let filtered = [];
      for (let exercise of exercises_array) {
        let title = this.exercise_title(exercise);
        let desc = this.exercise_description(exercise);
        let title_stripped = title.replace(/\./g, "");
        if (
          title.toLowerCase().includes(filter) ||
          desc.toLowerCase().includes(filter) ||
          title_stripped.toLowerCase().includes(filter) ||
          (exercise.provider_name &&
            exercise.provider_name.toLowerCase().includes(filter))
        ) {
          filtered.push(exercise);
        }
      }

      return filtered;
    },
    video_selections() {
      let selections = {};

      if (!this.current_clinic_selections) {
        return selections;
      }

      for (let builtin_select of this.current_clinic_selections.get_by_type(
        "video/builtin"
      )) {
        selections[builtin_select.entity_id] = builtin_select.selected;
      }
      for (let video_select of this.current_clinic_selections.get_by_type(
        "video_exercise"
      )) {
        selections[video_select.entity_id] = video_select.selected;
      }

      return selections;
    },

    sorted_exercises() {
      let grouped = [];

      for (let exercise of this.filtered_exercises) {
        if (exercise.deprecated) {
          continue;
        }

        if (this.video_selections[exercise.id] === false) {
          if (this.current_clinic_role !== "admin") {
            continue;
          } else if (!this.toggled.includes(exercise.id)) {
            exercise.group = ["Disabled"];
          }
        }

        if (exercise.group.length == 0) {
          exercise.group.push("Uncategorized");
        }
        if (!exercise.group[0]) {
          exercise.group[0] = "Uncategorized";
        }

        exercise.group.forEach((group_name) => {
          var group_exists = false;
          var group = {};

          for (var i in grouped) {
            if (grouped[i].group == group_name) {
              group_exists = true;
              group = grouped[i];
            }
          }
          if (!group_exists) {
            group = {
              group: group_name,
              anchor: "#" + group_name.replace(/\s+/g, ""),
              id: group_name.replace(/\s+/g, ""),
              exercises: [],
            };
          }
          group.exercises.push(exercise);
          if (!group_exists) {
            grouped.push(group);
          }
        });
      }

      // Sort the groups and exercises
      grouped.sort((a, b) => {
        if (a.group == "Uncategorized") {
          if (b.group == "Disabled") {
            return -1;
          }
          return 1;
        }
        if (b.group == "Uncategorized") {
          if (b.group == "Disabled") {
            return 1;
          }
          return -1;
        }
        if (a.group == "Disabled") {
          return 1;
        }
        if (b.group == "Disabled") {
          return -1;
        }

        return a.group > b.group ? 1 : -1;
      });

      // Sort exercises in groups
      for (var i in grouped) {
        grouped[i].exercises.sort((a, b) =>
          this.exercise_title(a) > this.exercise_title(b) ? 1 : -1
        );
      }

      return grouped;
    },
  },
  mounted() {
    // If we don't have a current clinic set, load it
    if (this.current_clinic_id && !this.current_clinic) {
      this.$store.dispatch("loadCurrentClinic", {
        clinic_id: this.current_clinic_id,
        callback: () => {
          this.load_clinic_videos();
        },
      });
    } else {
      this.load_clinic_videos();
    }
  },
  methods: {
    load_clinic_videos() {
      this.close_create_video_dialog();
      if (
        this.current_clinic_role == "admin" ||
        this.current_clinic_role == "staff"
      ) {
        this.$store.dispatch("loadClinicVideoExercises", () => {
          this.edit_video_dialog = {};
          for (let video_exercise of this.clinic_video_exercises) {
            Vue.set(this.edit_video_dialog, video_exercise.id, false);
          }
        });
      }
    },
    load_videos() {
      this.$store.dispatch("loadVideoExercises");
    },
    close_create_video_dialog() {
      this.create_video_dialog = false;
      this.new_video = {
        title: "",
        description: "",
        instructions: "",
        video: "",
        variant: "default",
        exercise_group: "",
        tools: [],
        clinic_id: this.current_clinic_id,
      };
    },
    close_video_dialog(exercise_id) {
      Vue.set(this.edit_video_dialog, exercise_id, false);
    },
    open_create_video_dialog() {
      this.create_video_dialog = true;
      Vue.nextTick(() => {
        if (this.$refs.create_video_dialog) {
          this.$refs.create_video_dialog.reset();
        }
      });
    },
    open_video_dialog(exercise_id) {
      Vue.set(this.edit_video_dialog, exercise_id, true);
      Vue.nextTick(() => {
        for (var elem of this.$refs.edit_video_dialog) {
          elem.reset();
        }
      });
    },
    confim_close_edit_video_dialog(index) {
      let uploading_video = false;
      for (var elem of this.$refs.edit_video_dialog) {
        if (elem.video_upload_in_progress) {
          uploading_video = true;
          break;
        }
      }
      if (uploading_video) {
        if (!window.confirm(this.$t("video_exercise_edit.confirm_close"))) {
          return;
        }
        for (var cancel_elem of this.$refs.edit_video_dialog) {
          cancel_elem.cancel_upload();
        }
      }
      this.close_video_dialog(index);
    },
    confim_close_create_video_dialog() {
      if (
        this.$refs.create_video_dialog &&
        this.$refs.create_video_dialog.video_upload_in_progress
      ) {
        if (!window.confirm(this.$t("video_exercise_edit.confirm_close"))) {
          return;
        }
        this.$refs.create_video_dialog.cancel_upload();
      }
      this.close_create_video_dialog();
    },
    exercise_title(exercise) {
      if (!exercise.title) {
        return "";
      }

      if (typeof exercise.title == "string") {
        return exercise.title;
      }

      let locale = this.$i18n.lang();
      if (exercise.title[locale]) {
        return exercise.title[locale];
      } else {
        return exercise.title.en;
      }
    },
    exercise_description(exercise) {
      if (!exercise.description) {
        return "";
      }
      if (typeof exercise.description == "string") {
        return exercise.description;
      }

      let locale = this.$i18n.lang();
      if (exercise.description[locale]) {
        return exercise.description[locale];
      } else {
        return exercise.description.en;
      }
    },
    close_dialog(exercise_id, index) {
      Vue.set(this.dialogs, exercise_id, false);
      this.exercise_stop(exercise_id, index);
    },
    exercise_stop(_exercise_id, _index) {
      for (var i in this.$refs["VideoExercise"]) {
        this.$refs["VideoExercise"][i].stop();
      }
    },
    open_exercise_dialog(exercise) {
      if (exercise.exercise_type == "interactive") {
        var calibration = this.current_calibration;
        if (!calibration && exercise.requires_calibration) {
          this.require_calibration_dialog = true;
        } else {
          Vue.set(this.dialogs, exercise.id, true);
          Vue.nextTick(function () {
            $("#exercise_config_focus").focus();
          });
        }
      } else {
        Vue.set(this.dialogs, exercise.id, true);
        Vue.nextTick(() => {
          for (var i in this.$refs["VideoExercise"]) {
            if (this.$refs["VideoExercise"][i].exercise.id == exercise.id) {
              this.$refs["VideoExercise"][i].load();
            }
          }
        });
      }
    },
    image_url(img_path) {
      return img_path;
    },
    item_background_color(index) {
      if (this.$vuetify.theme.dark) {
        return index % 2 == 1 ? "" : "rgba(255, 255, 255, 0.04)";
      } else {
        return index % 2 == 1 ? "" : "rgba(0, 0, 0, 0.04)";
      }
    },
    open_calibration_dialog() {
      this.$store.commit("setCalibrationDialog", true);
      this.require_calibration_dialog = false;
    },
    toggle_enabled(item) {
      if (this.in_progress[item.id]) {
        return;
      }

      if (this.current_clinic_role !== "admin") {
        messageBus.$emit(
          "error",
          this.$t("video_demo_list.please_ask_admin_to_enable")
        );
        return;
      }

      if (item.origin == "builtin") {
        let selection = this.current_clinic_selections.get_by_entity_id(
          item.id,
          "video/builtin"
        );

        if (selection) {
          if (selection.selected) {
            selection.selected = false;
          } else {
            selection.selected = true;
          }
        } else {
          selection = Selection.fromJson({
            clinic_id: this.current_clinic.id,
            entity_id: item.id,
            entity_type: "video/builtin",
            selected: false, // By default builtins are ON, so we set it to false
          });
        }

        this.toggled.push(item.id);

        Vue.set(this.in_progress, item.id, true);
        this.$store.dispatch("upsertSelection", {
          selection,
          callback: () => {
            Vue.set(this.in_progress, item.id, false);
          },
        });
      }
    },
  },
};

window.jQuery = $;
window.$ = $;
</script>

<style scoped>
.theme--light a.video_list_jump_link {
  background-color: transparent;
}
</style>
