<template>
  <div v-if="current_clinic" class="reading-demo-list">
    <v-app-bar
      app
      flat
      style="margin-top: 64px; border-bottom: 1px solid rgba(0, 0, 0, 0.2)"
    >
      <div>
        <v-select
          v-model="filter_text_language"
          :items="language_options"
          item-text="text"
          item-value="value"
          class="mt-6"
          style="max-width: 200px"
          outlined
          dense
        ></v-select>
      </div>
      <v-spacer />

      <v-text-field
        v-model="filter"
        append-icon="mdi-magnify"
        :label="$t('common.search')"
        single-line
        hide-details
        outlined
        dense
        style="max-width: 500px"
      ></v-text-field>

      <v-spacer />

      <v-btn class="mr-1" color="primary" @click="open_create_text_dialog">
        {{ $t("common.create_reading_text") }}
      </v-btn>

      <v-dialog
        v-model="create_text_dialog"
        max-width="1200"
        persistent
        @click:outside="confim_close_create_text_dialog"
      >
        <ReadingTextEdit
          v-if="create_text_dialog"
          :text="new_reading_text"
          @close="confim_close_create_text_dialog"
          @text-created="load_texts"
          @text-updated="load_texts"
        ></ReadingTextEdit>
      </v-dialog>
    </v-app-bar>
    <div
      v-if="
        sidebar &&
        $vuetify.breakpoint.name != 'xs' &&
        $vuetify.breakpoint.name != 'sm' &&
        sorted_texts.length > 1
      "
      class="category-list"
      style="position: fixed; top: 128px"
    >
      <v-navigation-drawer permanent>
        <v-list>
          <div v-for="group in sorted_texts" :key="group.group">
            <v-list-item :href="group.anchor" class="text_list_jump_link">
              <v-list-item-content v-if="group.group">
                <v-list-item-title> {{ group.group }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </v-navigation-drawer>
    </div>

    <div
      :style="
        sidebar &&
        $vuetify.breakpoint.name != 'xs' &&
        $vuetify.breakpoint.name != 'sm' &&
        sorted_texts.length > 1
          ? 'position: relative; left: 250px; padding-right: 270px'
          : ''
      "
    >
      <div v-for="group in sorted_texts" :key="group.group">
        <v-card style="border-radius: 0">
          <v-card-title primary-title>
            <h3
              :id="group.id"
              class="headline"
              style="
                padding-top: 140px;
                margin-top: -128px;
                padding-bottom: 4px;
                pointer-events: none;
              "
            >
              {{ group.group }}
            </h3>
          </v-card-title>

          <p v-if="group.desc" class="mx-4">
            {{ group.desc }}
          </p>

          <div>
            <v-list>
              <div v-for="(item, index) in group.texts" :key="index">
                <v-list-item
                  three-line
                  :style="
                    'cursor: pointer; background-color: ' +
                    item_background_color(index)
                  "
                  @click="open_text_dialog(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title style="font-weight: 550">
                      {{ item.title }}
                      <v-chip
                        v-if="item.is_new"
                        small
                        label
                        color="light-green"
                        style="margin-left: 10px"
                        >New</v-chip
                      >
                    </v-list-item-title>
                    <v-list-item-subtitle
                      ><span v-if="item.words"
                        >{{ item.words }} {{ $t("reading_demo_list.words") }}
                        <span v-if="item.description"> - </span></span
                      >{{ item.description }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle style="font-style: italic">
                      {{ item.attribution }}</v-list-item-subtitle
                    >
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-tooltip v-if="item.clinic_id && item.shared" top>
                      <template v-slot:activator="{ on }">
                        <v-chip
                          class="ma-2 mt-4"
                          style="width: 90px"
                          small
                          close
                          label
                          outlined
                          close-icon="mdi-share-variant"
                          v-on="on"
                        >
                          {{ $t("common.shared") }}</v-chip
                        >
                      </template>
                      <span>{{ $t("reading_demo_list.shared_exercise") }}</span>
                    </v-tooltip>
                    <v-tooltip
                      v-else-if="
                        item.clinic_id &&
                        !item.shared &&
                        !current_clinic.has_tag('public_reading_texts')
                      "
                      top
                    >
                      <template v-slot:activator="{ on }">
                        <v-chip
                          class="ma-2 mt-4"
                          style="width: 90px"
                          small
                          label
                          outlined
                          v-on="on"
                        >
                          {{ $t("video_demo_list.private") }}
                          <v-icon right x-small style="margin-left: 15px"
                            >fa fa-user-lock</v-icon
                          ></v-chip
                        >
                      </template>
                      <span>{{ $t("reading_demo_list.private_text") }}</span>
                    </v-tooltip>
                    <v-tooltip
                      v-else-if="
                        item.clinic_id &&
                        !item.shared &&
                        current_clinic.has_tag('public_reading_texts')
                      "
                      top
                    >
                      <template v-slot:activator="{ on }">
                        <v-chip
                          class="ma-2 mt-4"
                          style="width: 90px"
                          small
                          label
                          outlined
                          v-on="on"
                        >
                          {{ $t("video_demo_list.public") }}
                          <v-icon right x-small style="margin-left: 15px"
                            >fa fa-globe</v-icon
                          ></v-chip
                        >
                      </template>
                      <span>{{ $t("reading_demo_list.public_text") }}</span>
                    </v-tooltip>
                    <v-tooltip v-else-if="item.share_source" top>
                      <template v-slot:activator="{ on }">
                        <v-chip label small outlined class="mt-4" v-on="on">{{
                          $t("common.clinic_attribution", {
                            clinic_title: item.provider_name,
                          })
                        }}</v-chip>
                      </template>
                      <span>{{ $t("video_demo_list.public_exercise") }}</span>
                    </v-tooltip>
                  </v-list-item-action>
                  <v-list-item-action>
                    <div v-if="item.clinic_id">
                      <v-chip
                        class="ma-2 mt-3"
                        style="width: 90px; cursor: pointer"
                        label
                        outlined
                        color="primary"
                        v-on="on"
                        @click.stop="open_edit_text_dialog(item.id)"
                      >
                        <v-icon left x-small>fa fa-edit</v-icon>
                        {{ $t("common.edit") }}
                      </v-chip>

                      <v-dialog
                        v-model="edit_text_dialog[item.id]"
                        max-width="1200"
                        persistent
                        @click:outside="confim_close_edit_text_dialog(item.id)"
                      >
                        <ReadingTextEdit
                          v-if="edit_text_dialog[item.id]"
                          :text="item"
                          @close="confim_close_edit_text_dialog(item.id)"
                          @text-created="load_texts"
                          @text-updated="load_texts"
                          @text-deleted="load_texts"
                        ></ReadingTextEdit>
                      </v-dialog>
                    </div>
                  </v-list-item-action>
                </v-list-item>
                <v-divider />

                <div>
                  <v-dialog v-model="dialogs[item.id]" max-width="1000">
                    <v-card v-if="dialogs[item.id]">
                      <v-toolbar>
                        <v-btn icon @click="close_dialog(item.id)">
                          <v-icon>close</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                          {{ item.title }}
                        </v-toolbar-title>
                      </v-toolbar>

                      <v-layout justify-space-between class="ma-6 mb-n6">
                        <v-flex xs6>
                          <v-select
                            v-if="!exercise_launch_dialog"
                            v-model="selected_exercise"
                            :items="reading_exercises_options"
                            item-text="text"
                            item-value="value"
                            outlined
                            label="Exercise"
                          />
                        </v-flex>

                        <v-flex xs4>
                          <ExercisePresetSelect
                            v-if="!exercise_launch_dialog"
                            :preset_id="preset ? preset.id : null"
                            :label="$t('common.presets')"
                            :exercise_id="selected_exercise"
                            :current_exercise_config="current_exercise_config"
                            @preset_selected="preset_selected"
                          />
                        </v-flex>
                      </v-layout>

                      <ExerciseConfig
                        :key="selected_exercise"
                        :exercise="exercises[selected_exercise]"
                        :show_advanced="show_advanced_config"
                        :show_toolbar="false"
                        :default_config="default_config"
                        :preset="preset"
                        show_additional
                        regimen_config
                        @exercise_launch_dialog="update_exercise_launch_dialog"
                        @exercise-launch="update_config"
                        @close="close_dialog(item.id)"
                      />
                    </v-card>
                  </v-dialog>
                </div>
              </div>
            </v-list>
          </div>
        </v-card>
      </div>
    </div>

    <NoResults
      v-if="filter && !sorted_texts.length"
      :query="filter"
      class="mt-12"
    />

    <v-dialog v-model="require_calibration_dialog" max-width="400">
      <v-card v-if="require_calibration_dialog">
        <v-card-title class="headline">{{
          $t("common.calibration_is_required")
        }}</v-card-title>
        <v-card-text>{{
          $t("common.before_continue_we_need_to_calibrate_your_device")
        }}</v-card-text>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="open_calibration_dialog">{{
            $t("common.click_here_to_calibrate_your_device")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import $ from "jquery";
import ExerciseConfig from "./ExerciseConfig.vue";
import ReadingTextEdit from "./ReadingTextEdit";
import NoResults from "./NoResults.vue";
import ExercisePresetSelect from "./ExercisePresetSelect.vue";
import { mapState } from "vuex";
import { Selection } from "../classes/selection";
import { category_icons } from "../lib/category";
import languages from "../../../data/lang_codes.json";
import axios from "axios";
import Parser from "rss-parser/dist/rss-parser";

export default {
  components: {
    ExerciseConfig,
    ReadingTextEdit,
    NoResults,
    ExercisePresetSelect,
  },
  props: {
    sidebar: {
      type: Boolean,
      default: () => true,
    },
    show_advanced_config: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      filter: "",
      filter_text_language: this.$i18n.locale.substring(0, 2),
      dialogs: {},
      require_calibration_dialog: false,
      icons: category_icons,
      enabled_disabed: {},
      toggled: [], // List of currenly toggled videos, so we don't recategorize them imminentely
      in_progress: {}, // List of in-progress toggles
      create_text_dialog: false,
      edit_text_dialog: {},
      all_texts: [],
      conversation_items: {},
      preset: null,
      current_exercise_config: {},
      new_reading_text: {
        title: "",
        grade: 1,
        fulltext: "",
        questions: [],
        clinic_id: this.current_clinic_id,
        disposition: "all",
        attribution: "",
        lang: this.$i18n.lang(),
      },
      on: null,
      selected_exercise: "comprehension",
      selected_reading_text: "",
      exercise_launch_dialog: false,
    };
  },
  computed: {
    ...mapState([
      "current_user",
      "current_clinic_id",
      "current_clinic",
      "current_clinic_selections",
      "reading_texts",
      "current_clinic_role",
      "small_screen",
      "exercises",
      "video_exercises", // TODO: REMOVE
    ]),
    default_config() {
      return {
        texturl: this.selected_reading_text,
      };
    },
    reading_exercises_options() {
      let options = [];
      let reading_exercises = [
        "comprehension",
        "binocular_reading",
        "line_reading",
        "movingwindow",
        "wordreading",
      ];
      for (let key of reading_exercises) {
        let exercise = this.exercises[key];
        options.push({
          text: exercise.title[this.$i18n.locale.substring(0, 2)],
          value: exercise.id,
        });
      }
      return options;
    },
    available_langs() {
      let langs = [];
      for (let text of this.all_texts) {
        if (!langs.includes(text.lang)) {
          langs.push(text.lang);
        }
      }

      // Add in items from The Conversation if they don't already exist
      // Use Object.keys to get the keys of the object
      for (let lang of Object.keys(this.conversation_items)) {
        if (!langs.includes(lang)) {
          langs.push(lang);
        }
      }

      return langs;
    },
    language_options() {
      let language_options = [];
      for (let lang of this.available_langs) {
        let language = languages[lang];
        if (language) {
          language_options.push({
            text: language.nativeName,
            value: lang,
          });
        }
      }

      // Sort current UI language first (first two chars of locale)
      let current_lang = this.$i18n.locale.substring(0, 2);
      language_options.sort((a, b) => {
        if (a.value == current_lang) {
          return -1;
        }
        if (b.value == current_lang) {
          return 1;
        }
        return a.text > b.text ? 1 : -1;
      });

      return language_options;
    },
    filtered_texts() {
      let texts_array = this.all_texts;

      // Filter by filter_text_language
      texts_array = texts_array.filter((exercise) => {
        return exercise.lang == this.filter_text_language;
      });

      // Add in items from The Conversation in the current language
      if (this.conversation_items[this.filter_text_language]) {
        for (let convo_item of this.conversation_items[
          this.filter_text_language
        ]) {
          // Skip podcasts, COVID stuff, and anything obvertly political
          if (
            convo_item.title.includes("podcast") ||
            convo_item.summary.includes("podcast") ||
            convo_item.title.includes("COVID") ||
            convo_item.summary.includes("COVID") ||
            convo_item.title.includes("Trump") ||
            convo_item.summary.includes("Trump") ||
            convo_item.title.includes("Biden") ||
            convo_item.summary.includes("Biden")
          ) {
            continue;
          }

          let text = {
            title: convo_item.title,
            description: convo_item.summary,
            lang: this.filter_text_language,
            type: "feed",
            attribution: convo_item.author,
            id:
              "feed:theconversation_" +
              this.filter_text_language +
              ":" +
              convo_item.id,
          };
          texts_array.push(text);
        }
      }

      let filter = this.filter.trim().toLowerCase();
      if (!filter) {
        return texts_array;
      }

      let filtered = [];
      for (let text of texts_array) {
        let title = text.title;
        let attribution = text.attribution;
        let title_stripped = title.replace(/\./g, "");
        let desc = text.description || "";
        if (
          title.toLowerCase().includes(filter) ||
          attribution.toLowerCase().includes(filter) ||
          title_stripped.toLowerCase().includes(filter) ||
          desc.toLowerCase().includes(filter)
        ) {
          filtered.push(text);
        }
      }

      return filtered;
    },

    sorted_texts() {
      let grouped = [];

      for (let text of this.filtered_texts) {
        let group_exists = false;

        let group_name;
        let group_desc;
        if (text.type == "feed") {
          group_name = "The Conversation";
          group_desc = this.$t("reading_demo_list.the_conversation_desc");
        } else {
          let group_level = text.grade <= 12 ? text.grade : "13+";
          group_name = this.$t("common.level") + " " + group_level;
          group_desc = "";
        }

        let group = {};

        for (let i in grouped) {
          if (grouped[i].group == group_name) {
            group_exists = true;
            group = grouped[i];
          }
        }
        if (!group_exists) {
          group = {
            group: group_name,
            desc: group_desc,
            anchor: "#" + group_name.replace(/\s+/g, ""),
            id: group_name.replace(/\s+/g, ""),
            texts: [],
          };
        }
        group.texts.push(text);
        if (!group_exists) {
          grouped.push(group);
        }
      }

      // Sort the groups
      grouped.sort((a, b) => {
        // Regular expression to match the numeric part of the group strings
        const regex = /\d+/;

        // Extract numeric parts from the group strings
        const numA = parseInt(a.group.match(regex), 10);
        const numB = parseInt(b.group.match(regex), 10);

        // If both groups have numbers, compare them numerically
        if (!isNaN(numA) && !isNaN(numB)) {
          return numA - numB;
        }

        // If numeric comparison is not applicable, fall back to string comparison
        return a.group > b.group ? 1 : -1;
      });

      // Sort texts in groups
      for (var i in grouped) {
        grouped[i].texts.sort((a, b) => (a.title > b.title ? 1 : -1));
      }

      return grouped;
    },
  },
  watch: {
    selected_exercise() {
      this.preset = null;
      this.current_exercise_config = {};
    },
  },
  mounted() {
    // Load reading texts
    this.load_texts(true);

    // Load reading texts from The Conversation
    this.load_conversation_texts();

    // If we don't have a current clinic set, load it
    if (
      (this.current_clinic_id && !this.current_clinic) ||
      (this.current_clinic && this.current_clinic.id != this.current_clinic_id)
    ) {
      this.$store.dispatch("loadCurrentClinic", {
        clinic_id: this.current_clinic_id,
        callback: () => {
          this.load_clinic_texts();
        },
      });
    } else {
      this.load_clinic_texts();
    }
  },
  methods: {
    update_config(config) {
      this.current_exercise_config = config;
    },
    preset_selected(preset) {
      // Set the texturl in the preset selectd text if there is one
      if (preset) {
        if (this.selected_reading_text) {
          preset.config.texturl = this.selected_reading_text;
        }
        else {
          delete preset.config.texturl;
        }
      }
      this.preset = preset;
    },
    update_exercise_launch_dialog(value) {
      this.exercise_launch_dialog = value;
    },
    load_conversation_texts() {
      // Load RSS Articles
      let parser = new Parser();
      parser.parseURL("/api/reading_feed/theconversation_en").then((feed) => {
        // Remove the last two elements so to avoid race conditions with new content pushing them off between selecting and render.
        feed.items.pop();
        feed.items.pop();

        Vue.set(this.conversation_items, "en", feed.items);
      });
      parser.parseURL("/api/reading_feed/theconversation_fr").then((feed) => {
        // Remove the last two elements so to avoid race conditions with new content pushing them off between selecting and render.
        feed.items.pop();
        feed.items.pop();

        Vue.set(this.conversation_items, "fr", feed.items);
      });
      parser.parseURL("/api/reading_feed/theconversation_es").then((feed) => {
        // Remove the last two elements so to avoid race conditions with new content pushing them off between selecting and render.
        feed.items.pop();
        feed.items.pop();

        Vue.set(this.conversation_items, "es", feed.items);
      });
      parser.parseURL("/api/reading_feed/theconversation_id").then((feed) => {
        // Remove the last two elements so to avoid race conditions with new content pushing them off between selecting and render.
        feed.items.pop();
        feed.items.pop();

        Vue.set(this.conversation_items, "id", feed.items);
      });
      parser.parseURL("/api/reading_feed/theconversation_pt").then((feed) => {
        // Remove the last two elements so to avoid race conditions with new content pushing them off between selecting and render.
        feed.items.pop();
        feed.items.pop();

        Vue.set(this.conversation_items, "pt", feed.items);
      });
    },
    load_clinic_texts() {
      this.close_create_text_dialog();
      if (
        this.current_clinic_role == "admin" ||
        this.current_clinic_role == "staff"
      ) {
        this.$store.dispatch("loadReadingTexts", () => {
          this.edit_text_dialog = {};
          for (let reading_text of this.reading_texts) {
            Vue.set(this.edit_text_dialog, reading_text.id, false);
          }
        });
      }
    },
    load_texts(reset_selected_lang = false) {
      axios
        .get("/api/reading_text?clinic_id=" + this.current_clinic_id)
        .then((response) => {
          let texts = response.data.data;

          // Count words if there is fulltext but no words
          for (let text of texts) {
            if (text.fulltext && !text.words) {
              text.words = text.fulltext.split(/\s+/).length;
            }
          }

          this.all_texts = texts;

          // First preference is current browser language
          // Second preference is language select in app
          if (reset_selected_lang) {
            let browser_lang = navigator.language.substring(0, 2).toLowerCase();
            if (
              this.available_langs.includes(browser_lang) &&
              browser_lang != "en"
            ) {
              this.filter_text_language = browser_lang;
            } else {
              this.filter_text_language = this.$i18n.locale.substring(0, 2);
            }
          }
        });
    },
    close_create_text_dialog() {
      this.create_text_dialog = false;
      this.new_reading_text = {
        title: "",
        grade: 1,
        fulltext: "",
        questions: [],
        clinic_id: this.current_clinic_id,
        disposition: "all",
        attribution: "",
        lang: this.$i18n.lang(),
      };
    },
    close_text_dialog(exercise_id) {
      Vue.set(this.edit_text_dialog, exercise_id, false);
    },
    open_create_text_dialog() {
      this.create_text_dialog = true;
      this.new_reading_text = {
        title: "",
        grade: 1,
        fulltext: "",
        questions: [],
        clinic_id: this.current_clinic_id,
        disposition: "all",
        attribution: "",
        lang: this.$i18n.lang(),
      };
    },
    open_edit_text_dialog(exercise_id) {
      Vue.set(this.edit_text_dialog, exercise_id, true);
      Vue.nextTick(() => {
        for (var elem of this.$refs.edit_text_dialog) {
          elem.reset();
        }
      });
    },
    confim_close_edit_text_dialog(index) {
      // TODO
      this.close_text_dialog(index);
    },
    confim_close_create_text_dialog() {
      if (
        this.$refs.create_text_dialog &&
        this.$refs.create_text_dialog.unsaved_changes
      ) {
        if (
          !window.confirm(this.$t("clinic_page_manage_texts.confirm_close"))
        ) {
          return;
        }
      }
      this.close_create_text_dialog();
    },
    exercise_title(exercise) {
      if (!exercise.title) {
        return "";
      }

      if (typeof exercise.title == "string") {
        return exercise.title;
      }

      let locale = this.$i18n.lang();
      if (exercise.title[locale]) {
        return exercise.title[locale];
      } else {
        return exercise.title.en;
      }
    },
    exercise_description(exercise) {
      if (!exercise.description) {
        return "";
      }
      if (typeof exercise.description == "string") {
        return exercise.description;
      }

      let locale = this.$i18n.lang();
      if (exercise.description[locale]) {
        return exercise.description[locale];
      } else {
        return exercise.description.en;
      }
    },
    close_dialog(exercise_id, index) {
      Vue.set(this.dialogs, exercise_id, false);
      this.exercise_stop(exercise_id, index);
      this.exercise_launch_dialog = false;
    },
    exercise_stop(_exercise_id, _index) {
      for (var i in this.$refs["VideoExercise"]) {
        this.$refs["VideoExercise"][i].stop();
      }
    },
    open_text_dialog(text) {
      this.selected_reading_text = text.id;
      this.exercise_launch_dialog = false;
      if (this.preset) {
        this.preset.config.texturl = this.selected_reading_text;
      }
      Vue.nextTick(() => {
        Vue.set(this.dialogs, text.id, true);
        Vue.nextTick(() => {
          $("#exercise_config_focus").focus();
        });
      });
    },
    image_url(img_path) {
      return img_path;
    },
    item_background_color(index) {
      if (this.$vuetify.theme.dark) {
        return index % 2 == 1 ? "" : "rgba(255, 255, 255, 0.04)";
      } else {
        return index % 2 == 1 ? "" : "rgba(0, 0, 0, 0.04)";
      }
    },
    open_calibration_dialog() {
      this.$store.commit("setCalibrationDialog", true);
      this.require_calibration_dialog = false;
    },
    toggle_enabled(item) {
      if (this.in_progress[item.id]) {
        return;
      }

      if (this.current_clinic_role !== "admin") {
        messageBus.$emit(
          "error",
          this.$t("video_demo_list.please_ask_admin_to_enable")
        );
        return;
      }

      if (item.origin == "builtin") {
        let selection = this.current_clinic_selections.get_by_entity_id(
          item.id,
          "video/builtin"
        );

        if (selection) {
          if (selection.selected) {
            selection.selected = false;
          } else {
            selection.selected = true;
          }
        } else {
          selection = Selection.fromJson({
            clinic_id: this.current_clinic.id,
            entity_id: item.id,
            entity_type: "video/builtin",
            selected: false, // By default builtins are ON, so we set it to false
          });
        }

        this.toggled.push(item.id);

        Vue.set(this.in_progress, item.id, true);
        this.$store.dispatch("upsertSelection", {
          selection,
          callback: () => {
            Vue.set(this.in_progress, item.id, false);
          },
        });
      }
    },
  },
};

window.jQuery = $;
window.$ = $;
</script>

<style scoped>
.theme--light a.text_list_jump_link {
  background-color: transparent;
}
</style>
