// Imports
import Vue from "vue";
import VueRouter from "vue-router";
import i18n, { OpticalgymVueI18n } from "../shared/i18n";
import vuetify from "../shared/plugins/vuetify";
import * as Sentry from "@sentry/vue";
import { HttpClient } from "@sentry/integrations";

// global window properties
declare global {
  interface Window {
    OpticalGymMode: string;
    OpticalGymApp: string;
    OpticalGymVersion: string;
    OcudigitalLogError(error: Object, additional: { [key: string]: any }): void;
    VueApp: Vue;
    messageBus: Vue;
    globalMetronome: Vue;
  }
}

// Import app config
import "../shared/plugins/app_config";

// Registers window.OcudigitalLogError
import "../shared/plugins/log_error";

// TODO: typescriptafy this
// @ts-ignore
import routes from "./routes.js";

// TODO: typescriptafy this
// @ts-ignore
import App from "./App.vue";

// Global vue config
Vue.use(VueRouter);

if (window.OpticalGymMode != "development") {
  Vue.config.productionTip = false;
}

// Vuex Store
import store from "../shared/VuexStore";

// Router
var router = new VueRouter({
  routes: routes,
  mode: "history",
});

// Global message bus
window.messageBus = new Vue({
  i18n,
});

// Global metronome mini-app

// @ts-ignore
import GlobalMetronome from "../shared/GlobalMetronome.vue";

window.globalMetronome = new Vue({
  i18n,
  vuetify,
  render: (h) => h(GlobalMetronome),
}).$mount("#opticalgym_global_metronome");

let sentry_config: any = {
  Vue,
  environment: window.OpticalGymMode,
  dsn: "https://78c4386a38e9d4202ea33948dcf88c2d@o4506152454062080.ingest.sentry.io/4506152456486912",
  integrations: [new HttpClient()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "neurovisualtrainer.com", "app.visionxsports.com", "visionx-stage.ocudigital.com", "stage.ocudigital.com"],

  // Capture Replay for 1% of all sessions,
  // plus for 10% of sessions with an error
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 0.1,
};

if (window.OpticalGymVersion) {
  sentry_config.release = window.OpticalGymVersion;
}
Sentry.init(sentry_config);

// Initialize
window.VueApp = new Vue({
  i18n,
  vuetify,
  store,
  render: (h) => h(App),
  router: router,
}).$mount("#app");
