<template>
  <div class="monthly_reports">
    <!-- Toolbar -->
    <v-toolbar grey>
      <v-toolbar-title>Locales and Pricing</v-toolbar-title>
    </v-toolbar>

    <v-data-table dense :headers="headers" :items="locales" class="elevation-1" :items-per-page="-1">
      <template v-slot:item.price="{ item }">
        <v-btn
          icon
          x-small
          target="_blank"
          :href="
            'https://dashboard.stripe.com/prices/' + item.stripe_price.live
          "
        >
          <v-icon x-small>fas fa-external-link-alt</v-icon>
        </v-btn>
        {{ item.price }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import locales from "../../../../data/locales.yml";

export default {
  data() {
    let all_locales = [];
    for (const locale of Object.keys(locales)) {
      all_locales.push(locales[locale]);
    }
    return {
      locales: all_locales,
    headers: [
          { text: 'Name', value: 'name' },
          { text: 'Code', value: 'code' },
          { text: 'Monthly', value: 'price' },
          { text: 'Yearly', value: 'bulk_price' },
          { text: 'Currency', value: 'currency' },
          { text: 'Default Assoc.', value: 'default_association' },
      ],
    };
  },
};
</script> 
