<template>
  <div>
    <v-card max-width="1000" style="margin-top:50px; margin-left: 50px; padding: 20px">
      <p>{{ $t("equipment_store.weve_partnered_with_various_equipment_providers_message") }}</p>
    </v-card>

    <div
      v-for="(product, index) in products"
      :key="index"
      style="margin-top:50px; margin-left: 50px"
    >
      <v-card
        v-if="!product.exclusive_locale || (current_clinic && product.exclusive_locale == current_clinic.billing_country.toUpperCase())"
        max-width="1000"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <v-layout>
              <v-flex md8>
                <div class="overline mb-4">{{ translated(product.caption) }}</div>
                <v-list-item-title class="headline mb-1">{{ translated(product.name) }}</v-list-item-title>
                <v-list-item-subtitle>{{ translated(product.price) }}</v-list-item-subtitle>

                <div style="margin-top: 10px" v-html="translated(product.description)"></div>
                <div v-if="product.promo_code">
                  {{ $t("equipment_store.use_promo_code") }}
                  <span
                    style="font-weight: bold"
                  >{{ product.promo_code }}</span>
                  {{ $t("equipment_store.on_checkout") }}
                </div>
              </v-flex>
              <v-flex md4>
                <a :href="product.image" target="_blank">
                  <v-img
                    :src="product.image"
                    style="float:right; margin:20px"
                    height="200"
                    width="200"
                    max-width="200"
                  />
                </a>
              </v-flex>
            </v-layout>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- :disabled="pack_size_selection == null" -->
          <v-btn
            style="margin-right: 20px; margin-bottom: 20px"
            color="primary"
            :href="product.link"
            target="_blank"
          >{{ $t("equipment_store.view_product") }}</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    current_user: {
      type: Object,
      required: true,
    },
    user_invites: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      products: [
        {
          name: {
            en: "NeuroVisual Trainer Patient Kit",
            es: "Kit para pacientes de NeuroVisual Trainer",
            fr: "NeuroVisual Trousse d’entraînement pour le patient",
          },
          price: {
            en: "$99.95 USD with promo code",
            es: "$99.95 USD con el código de promoción",
            fr: "99,95$US avec le code promotionnel",
          },
          caption: {
            en: "PROVIDED BY BERNELL",
            es: "PROVISTO POR BERNELL",
            fr: "FOURNIE PAR BERNELL",
          },
          link: "https://www.bernell.com/product/NVTKIT1/h",
          image:
            "https://s3.amazonaws.com/cdn.bernell.com/images/uploads/6712_11388_popup.jpg",
          description: {
            en: "<p>The NeuroVisual Trainer Recommended Patient Kit comprises an assortment of essential vision therapy equipment items recommended by a panel of Behavioral Optometrists who currently practice in large VT clinics. This kit is a great starting point for patients beginning vision therapy.</p>",
            es: "<p>El kit recomendado para pacientes NeuroVisual Trainer comprende una variedad de equipos esenciales para la terapia visual recomendados por un panel de optometristas conductuales que actualmente ejercen en grandes clínicas. Este kit es un excelente punto de partida para los pacientes que comienzan con terapia visual.</p>",
            fr: "<p>La trousse recommandée inclut un assortiment d’items d’équipement essentiel pour la rééducation visuelle, recommandés par un ensemble d’optométristes béhavioraux qui pratiquent la rééducation visuelle. Cet ensemble est un bon point de départ pour les patients débutant la rééducation visuelle</p>",
          },
          promo_code: "NVTC1030K",
        },
        {
          name: {
            en: "Anaglyph Glasses",
            es: "Gafas de anaglifo",
          },
          exclusive_locale: "CA",
          price: {
            en: "From $4.25 CAD per unit",
            es: "Desde $4.25 CAD por unidad",
          },
          caption: {
            en: "Provided by Brock String Canada",
            es: "Provisto por Brock String Canada",
          },
          link: "https://brockstring.ca/shop/ols/categories/anaglyph-glasses",
          image:
            "https://isteam.wsimg.com/ip/0f972d67-46f9-410f-b67f-ea9ef6d8fe1c/ols/24_original",
          description: {
            en: "<p>High quality Red Cyan Anaglyph glasses. Red is on the left, Cyan on the right. Large size so it fits nicely over almost any set of glasses.</p>",
            es: "<p>Gafas de anaglifo Rojo-Cian (azul) de alta calidad. El rojo está a la izquierda, Cian a la derecha. De tamaño grande, por lo que se adapta perfectamente a casi cualquier juego de gafas.</p>",
          },
        },
        {
          name: {
            en: "Anaglyph Glasses",
            es: "Gafas de anaglifo",
            fr: "Lunettes anaglyphes",
          },
          price: {
            en: "From $3.90 USD per unit with promo code",
            es: "Desde $3.90 USD por unidad con el código de promoción",
            fr: "À partir de 3,90$US par unité avec un code promotionnel",
          },
          caption: {
            en: "Provided by Bernell",
            es: "Provisto por Bernell",
            fr: "FOURNIES PAR BERNELL",
          },
          link: "https://www.bernell.com/product/BC1180",
          image: "/assets/products/bernell_anaglyph_1.png",
          description: {
            en: "<p>High quality anaglyph glasses that provide good cancellation when doing interactive exercises on NeuroVisual Trainer. Available with both temples and elastics. </p>",
            es: "<p>Gafas de anaglifo de alta calidad que proporcionan una buena supresión al realizar los ejercicios interactivos en NeuroVisual Trainer. Disponible con patillas y elásticos. </p>",
            fr: "<p>Lunettes anaglyphes de grande qualité qui procurent une bonne neutralisation pour exécuter les exercices interactifs du Neurovisual trainer. Disponibles avec branches et élastiques.</p>",
          },
          promo_code: "NVTC1030K",
        },
        {
          name: {
            en: "Child Size Reversible Goggles",
            es: "Gafas reversibles para niños",
            fr: "Lunettes réversibles de grandeur pour enfants",
          },
          price: {
            en: "From $3.90 USD per unit with promo code",
            es: "Desde $3.90 USD por unidad con el código de promoción",
            fr: "À partir de 3,90$US par unité avec un code promotionnel",
          },
          caption: {
            en: "Provided by Bernell",
            es: "Provisto por Bernell",
            fr: "FOURNIES PAR BERNELL",
          },
          link: "https://www.bernell.com/product/BC1186",
          image: "/assets/products/bernell_anaglyph_child.png",
          description: {
            en: "<p>High quality anaglyph glasses that provide good cancellation when doing interactive exercises on NeuroVisual Trainer. Available with both temples and elastics. </p>",
            es: "<p>Gafas de anaglifo de alta calidad que proporcionan una buena supresión al realizar los ejercicios interactivos en NeuroVisual Trainer. Disponible con patillas y elásticos. </p>",
            fr: "<p>Lunettes anaglyphes de haute qualité qui procurent une bonne neutralisation pour exécuter les exercices interactifs du Neurovisual Trainer. Disponibles avec branches et élastiques.</p>",
          },
          promo_code: "NVTC1030K",
        },
        {
          name: {
            en: "Brock Strings",
            es: "Cuerdas de Brock",
          },
          exclusive_locale: "CA",
          price: {
            en: "From $6.00 CAD per unit",
            es: "Desde $6.00 CAD por unidad",
          },
          caption: {
            en: "Provided by Brock String Canada",
            es: "Provisto por Brock String Canada",
          },
          link: "https://brockstring.ca/shop/ols/categories/brock-strings",
          image:
            "https://isteam.wsimg.com/ip/0f972d67-46f9-410f-b67f-ea9ef6d8fe1c/ols/1_original",
          description: {
            en: "<p>This 12 ft long Brock String with 5 beads, yellow, red, blue, green and black is a tried and true vision therapy staple.  The ends are knotted to ensure that that beads don't slide off.  The string and beads are set up so that they do not slide without being physically moved, so you can avoid the unintentional bead slip. </p>",
            es: "<p>Esta cadena de Brock de 12 pies de largo con 5 cuentas: amarillo, rojo, azul, verde y negro es un clásico en la terapia visual. Los extremos están anudados para asegurar que las cuentas no se deslicen. La cuerda y las cuentas están arregladas de tal manera que no se deslicen sin que sean empujadas físicamente, para que se evite el deslizamiento involuntario de las cuentas. </p>",
          },
        },
        {
          name: {
            en: "Accommodative Flippers",
            es: "Aletas acomodativas",
          },
          exclusive_locale: "CA",
          price: {
            en: "From $15.00 CAD per unit",
            es: "Desde $15.00 CAD por unidad",
          },
          caption: {
            en: "Provided by Brock String Canada",
            es: "Provisto por Brock String Canada",
          },
          link: "https://brockstring.ca/shop/ols/categories/accommodative-flippers",
          image:
            "https://isteam.wsimg.com/ip/0f972d67-46f9-410f-b67f-ea9ef6d8fe1c/ols/9_original",
          description: {
            en: "<p>High quality accommodative flippers with adjustable PD. The mechanism nicely holds the lenses at the set PD.  Available in various powers. +-0.50, +-0.75, +-1.00, +-1.25, +-1.50, +-1.75, +-2.00, +-2.25, +-2.50 and any custom configuration you would like. </p>",
            es: "<p>Aletas acomodativas de alta calidad con PD ajustable. El mecanismo sostiene muy bien las lentes en el PD configurado. Disponible en varias potencias. + -0,50, + -0,75, + -1,00, + -1,25, + -1,50, + -1,75, + -2,00, + -2,25, + -2,50 y cualquier configuración personalizada que desee. </p>",
          },
        },
      ],
    };
  },
  computed: {
    ...mapState(["current_clinic", "current_clinic_id"]),
  },
  methods: {
    translated(prop) {
      let locale = this.$i18n.lang();
      if (prop[locale]) {
        return prop[locale];
      } else {
        return prop.en;
      }
    },
  },
};
</script>
