<template>
  <div>
    <!-- All Clinics List -->
    <v-data-table
      :items="shared"
      :headers="table_headers"
      :items-per-page="1000"
      :search="search"
      :fixed-header="true"
    >
      <template v-slot:item.title="{ item }">
        <v-icon left>{{ entity_icons[item.entity_type] }}</v-icon>
        {{ item.title }}
        <span v-if="item.shared.auto_import" style="font-style: italic">(dependency)</span>
      </template>

      <template v-slot:item.shared="{ item }">
        <span
          v-if="(item.shared.quality && item.shared.quality != 'poor') && !item.shared.auto_import"
        >Visible</span>
        <span v-else style="font-style: italic">Not Visible</span>
      </template>

      <template v-slot:item.clinic_title="{ item }">
        <a
          :href="'/app/clinic/' + item.clinic_id"
          target="_blank"
          style="font-size: smaller"
        >{{ item.clinic_title }}</a>
      </template>

      <template v-slot:item.shared.quality="{ item }">
        <v-btn v-if="!item.shared.auto_import" icon @click="edit_dialogs[item.id] = true">
          <v-icon small>edit</v-icon>
        </v-btn>
        <span v-if="item.shared.auto_import">
          <span style="font-style: italic">Dependency</span>
        </span>
        <span v-else-if="item.shared.quality === null">
          ❗ <span style="font-style: italic">Needs Review</span>
        </span>
        <span v-else-if="item.shared.quality === 'poor'">❌ Poor (Rejected)</span>
        <span v-else-if="item.shared.quality === 'fair'">✅ Fair</span>
        <span v-else-if="item.shared.quality === 'good'">✅ Good</span>
        <span v-else-if="item.shared.quality === 'excellent'">✅ Excellent</span>
      </template>

      <template v-slot:item.entity_type="{ item }">
        <v-btn icon outlined @click="open_dialog(item)">
          <v-icon color="blue">mdi-play</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <div>
      <div v-for="item in shared" :key="item.id">
        <v-dialog
          v-model="dialogs[item.id]"
          :max-width="item.entity_type == 'file' ? 600 : 1200"
          @click:outside="exercise_stop()"
        >
          <GridSetEdit
            v-if="item.entity_type == 'grid_set'"
            ref="grid_set_edit"
            readonly
            :grid_set_id="item.id"
            :clinic_id="item.clinic_id"
            :additional_video_exercises="additional_video_exercises"
            @close="close_dialog(item.id)"
          ></GridSetEdit>
          <GridScheduleEdit
            v-else-if="item.entity_type == 'grid_schedule'"
            ref="grid_schedule_edit"
            readonly
            :grid_schedule_id="item.id"
            :clinic_id="item.clinic_id"
            :additional_video_exercises="additional_video_exercises"
            @close="close_dialog(item.id)"
          ></GridScheduleEdit>
          <ReadingTextEdit
            v-else-if="item.entity_type == 'reading_text'"
            :text="item"
            readonly
            @close="close_dialog(item.id)"
          ></ReadingTextEdit>
          <ToolStaticPDF
            v-if="item.entity_type == 'file'"
            :title="item.title"
            :description="item.description"
            :uri="file_base_url + item.clinic_id + '/' + item.id + '/1.' + item.filename"
            @close="close_dialog(item.id)"
          ></ToolStaticPDF>
          <VideoExercise
            v-if="item.entity_type == 'video_exercise'"
            ref="VideoExercise"
            :exercise="video_transform(item)"
            show_config
            :trusted="false"
            @close="close_dialog(item.id)"
            @exercise-finished="close_dialog(item.id)"
          ></VideoExercise>
        </v-dialog>
      </div>
    </div>

    <div>
      <div v-for="item in shared" :key="item.id">
        <v-dialog
          v-model="edit_dialogs[item.id]"
          :max-width="600"
        >
          <v-card>
            <v-card-title>
              <v-icon left>{{ entity_icons[item.entity_type] }}</v-icon>
              {{ item.title }}
            </v-card-title>
            <v-card-text>
              <v-select
                v-model="item.shared.quality"
                :items="review_options"
                label="Review"
                @change="save_quality(item)"
              ></v-select>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import $ from "jquery";
import VideoExercise from "../VideoExercise";
import GridSetEdit from "../GridSetEdit";
import GridScheduleEdit from "../GridScheduleEdit";
import ReadingTextEdit from "../ReadingTextEdit";
import ToolStaticPDF from "../ToolStaticPDF";
import { mapState } from "vuex";
import { category_icons } from "../../lib/category";
import axios from "axios";
import languages from "../../../../data/lang_codes.json";
import { clinic_to_builtin } from "../../classes/video_exercise";

export default {
  components: { VideoExercise, GridSetEdit, GridScheduleEdit, ReadingTextEdit, ToolStaticPDF },
  props: {},
  data() {
    return {
      icons: category_icons,
      shared: [],
      languages,
      search: "",
      entity_types: ["grid_set", "grid_schedule", "reading_text", "file", "video_exercise"],
      dialogs: {},
      edit_dialogs: {},
      entity_icons: {
        grid_set: "fas fa-align-justify",
        grid_schedule: "fas fa-border-all",
        reading_text: "mdi-file-document-box-outline",
        file: "print",
        video_exercise: "video_library",
      },
      entity_titles: {
        grid_set: this.$t("community_sharing.grid_set"),
        grid_schedule: this.$t("community_sharing.grid_schedule"),
        reading_text: this.$t("community_sharing.reading_text"),
        file: this.$t("community_sharing.printable_sheet"),
        video_exercise: this.$t("community_sharing.video_exercise"),
      },
      table_headers: [
        { text: "Title", value: "title" },
        { text: "Visible", value: "shared" },
        { text: "Source", value: "clinic_title" },
        { text: "Status", value: "shared.quality" },
        { text: "Count", value: "shared.count" },
        { text: "", value: "entity_type" },
      ],
      review_options: [
        { value: null, text: "❗ Not Reviewed"},
        { value: "poor", text: "❌ Poor (Rejected)" },
        { value: "fair", text: "✅ Fair (Accepted below fold)" },
        { value: "good", text: "✅ Good (Accepted)" },
        { value: "excellent", text: "✅ Excellent (Accepted)" },
      ]
    };
  },
  computed: {
    ...mapState(["file_base_url", "video_exercises", "grid_sets", "grid_schedules", "reading_texts"]),
    additional_video_exercises() {
      let additional_video_exercises = {};
      for (let shared of this.shared) {
        if (shared.entity_type == "video_exercise") {
          let additional_video = clinic_to_builtin(shared);
          additional_video_exercises[additional_video.id] = additional_video;
        }
      }
      return additional_video_exercises;
    },
  },
  mounted() {
    this.load_shared();
  },
  methods: {
    load_shared() {
      axios.get("/api/shared").then((response) => {
        if (response.data.status == "success") {
          this.shared = response.data.data;
          for (let entity of this.shared) {
            Vue.set(this.dialogs, entity.id, false);
            Vue.set(this.edit_dialogs, entity.id, false);
          }
        }
      });
    },
    exercise_title(exercise) {
      if (!exercise.title) {
        return "";
      }

      if (typeof exercise.title == "string") {
        return exercise.title;
      }

      let locale = this.$i18n.lang();
      if (exercise.title[locale]) {
        return exercise.title[locale];
      } else {
        return exercise.title.en;
      }
    },
    exercise_description(exercise) {
      if (!exercise.description) {
        return "";
      }
      if (typeof exercise.description == "string") {
        return exercise.description;
      }

      let locale = this.$i18n.lang();
      if (exercise.description[locale]) {
        return exercise.description[locale];
      } else {
        return exercise.description.en;
      }
    },
    open_dialog(exercise) {
      Vue.set(this.dialogs, exercise.id, true);
      if (exercise.entity_type == "video_exercise") {
        Vue.nextTick(() => {
          for (var i in this.$refs["VideoExercise"]) {
            if (this.$refs["VideoExercise"][i].exercise.id == exercise.id) {
              this.$refs["VideoExercise"][i].load();
            }
          }
        });
      }
    },
    close_dialog(exercise_id) {
      Vue.set(this.dialogs, exercise_id, false);
      this.exercise_stop();
    },
    exercise_stop() {
      for (var i in this.$refs["VideoExercise"]) {
        this.$refs["VideoExercise"][i].stop();
      }
    },
    video_transform(video_entity) {
      return clinic_to_builtin(video_entity);
    },
    save_quality(item) {
      Vue.set(this.edit_dialogs, item.id, false);

      let url = "/api/shared/" + item.entity_type + "/" + item.id;

      axios.post(url, item.shared).then((resp) => {
        if (resp.data.status == "success") {
          messageBus.$emit("success", "Updated");
        }
        else {
          messageBus.$emit("api-error", resp);
        }
      });
    }
  },
};
</script>
