<template>
  <v-toolbar dark flat style="max-height: 65px" class="page_toolbar">
    <v-toolbar-title>
      <v-icon left>fa-cogs</v-icon>Administration
    </v-toolbar-title>
    <v-spacer />
    <v-toolbar-items>
      <v-btn text to="/app/admin/clinics">
        <v-icon left>business</v-icon>Clinics
      </v-btn>
      <v-btn text to="/app/admin/revisions">
        <v-icon left>fa-database</v-icon>Revisions
      </v-btn>
      <v-btn text to="/app/admin/users">
        <v-icon left>account_circle</v-icon>Users
      </v-btn>
      <v-btn text to="/app/admin/locales">
        <v-icon left>fas fa-globe</v-icon>Locales
      </v-btn>
      <v-btn text to="/app/admin/charges">
        <v-icon left>attach_money</v-icon>Charges
      </v-btn>
      <v-btn text to="/app/admin/shared">
        <v-icon left>mdi-share-variant</v-icon>Shared
      </v-btn>
      <v-btn text to="/app/admin/monthly">
        <v-icon left>assessment</v-icon>KPI
      </v-btn>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
export default {};
</script>
