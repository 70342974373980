<template>
  <div>
    <div v-if="admin_or_staff">
      <v-app-bar dark flat app class="page_toolbar">
        <v-toolbar-title>
          <v-icon left>fas fa-border-all</v-icon>
          {{ $t('clinic_page.grids') }}
        </v-toolbar-title>
      </v-app-bar>

      <ClinicGrids
        v-if="current_user && current_clinic"
        ref="clinic_grids"
        :current_user="current_user"
        :current_user_role="current_clinic_role"
        :clinic="current_clinic"
        :exercises="exercises"
        :video_exercises="video_exercises"
        :grid_sets="grid_sets"
        :grid_schedules="grid_schedules"
        :show_id="current_user.is_admin"
        @grid-set-created="load_grids"
        @grid-set-updated="load_grids"
        @grid-set-deleted="load_grids"
        @grid-schedule-created="load_grids"
        @grid-schedule-updated="load_grids"
        @grid-schedule-deleted="load_grids"
      />
    </div>
    <PermissionDenied v-else />
  </div>
</template>

<script>
import ClinicGrids from "../../shared/components/ClinicGrids";
import PermissionDenied from "../../shared/components/PermissionDenied";
import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    ClinicGrids,
    PermissionDenied,
  },
  data() {
    return {
      user_invites: this.$root.$children[0].user_invites,
    };
  },
  computed: {
    ...mapState(["current_clinic_id", "current_clinic", "current_user", "video_exercises", "grid_sets", "grid_schedules", "exercises", "current_clinic_role"]),
    admin_or_staff() {
      if (this.current_user.is_admin) {
        return true;
      }
      for (var i in this.user_invites) {
        let invite = this.user_invites[i];
        if (invite.user_role == "admin" || invite.user_role == "staff") {
          return true;
        }
      }
      return false;
    },
  },
  mounted() {
    this.load_current_clinic();
  },
  methods: {
    load_current_clinic() {
      this.$store.dispatch("loadCurrentClinic", { clinic_id: this.current_clinic_id });
    },
    load_grids() {
      this.$store.dispatch("loadGrids");
    },
  }
};
</script>

<style>
</style>