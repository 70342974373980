<template>
  <v-app>
    <v-toolbar style="max-height: 65px" dark flat class="page_toolbar">
      <v-toolbar-title>
        <v-icon left>build</v-icon>{{ $t("common.tools") }}
      </v-toolbar-title>
    </v-toolbar>
    <RegimenReport
      v-if="current_user && current_clinic && current_invite && user_invites && exercises && video_exercises"
      :invite_id="current_invite.id"
      :clinic_id="current_clinic.id"
      :exercises="exercises"
      :video_exercises="video_exercises"
      :user="current_user"
      :toolbar_close="false"
      :allow_edit_assigned="false"
    />
  </v-app>
</template>

<script>
import RegimenReport from "../../shared/components/RegimenReport";
import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    RegimenReport
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["exercises", "video_exercises", "current_clinic", "user_invites", "current_user"]),
    current_invite() {
      for (let invite of this.user_invites) {
        if (invite.clinic_id == this.current_clinic.id) {
          return invite;
        }
      }
      return null;
    }
  },
  mounted() {
    // If there's nothing loaded, we landed directly on the progress report URL, just direct to the main clinic page
    if (!this.current_clinic) {
      this.$router.push("/app")
    }
  }
};
</script>

<style>
</style>

