<template>
  <v-app>
    <div v-if="admin_or_staff">
      <v-toolbar style="max-height: 65px" dark flat class="page_toolbar">
        <v-toolbar-title>
          <v-icon left>ondemand_video</v-icon>
          {{ $t("common.video_exercises") }}
        </v-toolbar-title>

        <v-spacer />

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('common.search')"
          single-line
          hide-details
          outlined
          dense
          style="max-width: 500px;"
        ></v-text-field>
      </v-toolbar>
      <VideoDemoList :filter="search" />
    </div>
    <PermissionDenied v-else />
  </v-app>
</template>

<script>
import VideoDemoList from "../../shared/components/VideoDemoList";
import PermissionDenied from "../../shared/components/PermissionDenied";

export default {
  name: "App",
  components: {
    VideoDemoList,
    PermissionDenied,
  },
  data() {
    return {
      search: "",
      current_user: this.$root.$children[0].current_user,
      user_invites: this.$root.$children[0].user_invites,
    };
  },
  computed: {
    admin_or_staff() {
      if (this.current_user.is_admin) {
        return true;
      }
      for (var i in this.user_invites) {
        let invite = this.user_invites[i];
        if (invite.user_role == "admin" || invite.user_role == "staff") {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style>
</style>