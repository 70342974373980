<template>
  <div>
    <v-card class="mx-auto" max-width="1000" style="margin-top: 50px">
      <v-card-title v-if="sharing_clinic">
        <h2 class="headline">{{ sharing_clinic.title }}</h2>
      </v-card-title>

      <v-card-text>
        <a :href="website_link" target="_blank" style="text-decoration:none"> 
          <h3>{{ website_view }}</h3>
        </a>
      </v-card-text>

      <v-card-text>
        <CommunityContent
          :sidebar="false"
          :show_attribution="false"
          :clinic_id_filter="clinic_id"
          one_list
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import CommunityContent from "./CommunityContent";
import { mapState } from "vuex";
import axios from "axios";

export default {
  components: { CommunityContent },
  props: {
    filter: {
      type: String,
      default: "",
    },
    clinic_id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      sharing_clinics: [],
    };
  },
  computed: {
    ...mapState([]),
    sharing_clinic() {
      return this.sharing_clinics.find((c) => c.id == this.clinic_id);
    },
    website_view() {
      if (this.sharing_clinic) {
        return this.sharing_clinic.website.replace(/(^\w+:|^)\/\//, "");
      }
      return "";
    },
    website_link() {
      if (this.sharing_clinic) {
        if (this.sharing_clinic.website.startsWith("http://") || this.sharing_clinic.website.startsWith("https://")) {
          return this.sharing_clinic.website;
        } else {
          return "http://" + this.sharing_clinic.website;
        }
      }
      return "";
    },
  },
  mounted() {
    this.load_sharing_clinics();
  },
  methods: {
    load_sharing_clinics() {
      axios.get("/api/shared/clinics").then((resp) => {
        if (resp.data.status == "success") {
          this.sharing_clinics = resp.data.data;
        }
      });
    },
  },
};
</script>

