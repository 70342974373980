<template>
  <v-app>
    <div v-if="current_user.is_admin">
      <AdminMenuToolbar />
      <AllClinics :current_user="current_user" />
    </div>
    <PermissionDenied v-else />
  </v-app>
</template>

<script>
import AllClinics from "../../shared/components/admin/AllClinics";
import PermissionDenied from "../../shared/components/PermissionDenied";
import AdminMenuToolbar from "./AdminMenuToolbar"


export default {
  name: "App",
  components: {
    AllClinics,
    PermissionDenied,
    AdminMenuToolbar
  },
  data() {
    return {
      current_user: this.$root.$children[0].current_user,
      user_invites: this.$root.$children[0].user_invites,
    };
  },
};
</script>
