<template>
  <v-app>
    <ClinicPage
      :clinic_id="clinic_id"
      :current_user="current_user"
      :user_invites="user_invites"
    />
  </v-app>
</template>

<script>
import ClinicPage from "../../shared/components/ClinicPage";

export default {
  components: {
    ClinicPage,
  },
  data() {
    return {
      clinic_id: this.$route.params.id,
      current_user: this.$root.$children[0].current_user,
      user_invites: this.$root.$children[0].user_invites,
    };
  },
  watch: {
    $route(to, from) {
      if (to.params.id != from.params.id) {
        window.location.reload();
      }
    },
  },
  mounted() {
    this.$store.commit("setCurrentClinicId", this.$route.params.id);
  },
};
</script>

<style></style>
