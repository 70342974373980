<template>
  <div
    v-if="current_clinic"
    class="video-demo-list"
    style="margin-bottom: 60px"
  >
    <v-app-bar
      app
      flat
      style="margin-top: 64px; border-bottom: 1px solid rgba(0, 0, 0, 0.2)"
    >
      <v-text-field
        v-model="filter"
        append-icon="mdi-magnify"
        :label="$t('common.search')"
        single-line
        hide-details
        outlined
        dense
        style="max-width: 500px"
      ></v-text-field>

      <v-spacer />
    </v-app-bar>
    <div
      v-if="
        sidebar &&
        $vuetify.breakpoint.name != 'xs' &&
        $vuetify.breakpoint.name != 'sm'
      "
      :class="'category-list ' + (dense ? 'dense' : '')"
      style="position: fixed; top: 128px"
    >
      <v-navigation-drawer permanent floating>
        <v-list>
          <div v-for="entity_type in entity_types" :key="entity_type">
            <div
              v-if="
                (entity_type != 'video_exercise' &&
                  sorted_exercises[entity_type] &&
                  sorted_exercises[entity_type].items &&
                  sorted_exercises[entity_type].items.length) ||
                (entity_type == 'video_exercise' &&
                  sorted_exercises.video_exercise &&
                  sorted_exercises.video_exercise.grouped &&
                  sorted_exercises.video_exercise.grouped.length)
              "
            >
              <v-list-item
                :href="'#' + entity_type"
                class="shared_content_list_jump_link"
              >
                <v-list-item-action v-if="!dense">
                  <v-icon>{{ entity_icons[entity_type] }}</v-icon>
                </v-list-item-action>

                <v-icon v-else small :left="!$vuetify.rtl" :right="$vuetify.rtl">{{
                  entity_icons[entity_type]
                }}</v-icon>

                <v-list-item-content>
                  <v-list-item-title
                    :style="dense ? 'font-size: 0.9rem' : ''"
                    >{{ entity_titles_plural[entity_type] }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>

          <!-- Exercise subcategories -->
          <div
            v-for="group in sorted_exercises.video_exercise.grouped"
            :key="group.group"
          >
            <v-list-item
              :href="group.anchor"
              :class="'shared_content_list_jump_link video_exercise_group_jump_link ' + ($vuetify.rtl ? 'rtl' : '')"
            >
              <v-list-item-content v-if="group.group">
                <v-list-item-title
                  :style="dense ? 'font-size: 0.8rem' : 'font-size: 0.9rem'"
                >
                  <v-icon small :left="!$vuetify.rtl" :right="$vuetify.rtl">{{ icons[group.group] }}</v-icon>
                  {{ $t("group." + group.group) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </v-navigation-drawer>
    </div>

    <div :style="mainlist_style">
      <v-sheet
        v-if="!filter && !clinic_id_filter"
        elevation="5"
        class="pa-6 ml-6 mr-6 mb-6"
      >
        <p>{{ $t("community_content.welcome_1") }}</p>
        <p>{{ $t("community_content.welcome_2") }}</p>

        <div
          style="width: 100%; display: flex !important"
          class="justify-center"
        >
          <iframe
            class="sproutvideo-player"
            style="
              margin: auto;
              text-align: center;
              width: 630px;
              height: 354px;
            "
            src="https://videos.sproutvideo.com/embed/a79fdcbd141de0c62e/11d721751f1bb8a8"
            frameborder="0"
            allowfullscreen
            referrerpolicy="no-referrer-when-downgrade"
            title="Video Player"
          ></iframe>
        </div>
      </v-sheet>

      <NoResults
        v-if="filter && total_count == 0 && !loading"
        :query="filter"
        class="mt-12"
      />

      <div v-for="entity_type in entity_types" :key="entity_type">
        <v-card
          v-if="
            (entity_type != 'video_exercise' &&
              sorted_exercises[entity_type] &&
              sorted_exercises[entity_type].items &&
              sorted_exercises[entity_type].items.length) ||
            (entity_type == 'video_exercise' &&
              sorted_exercises.video_exercise &&
              sorted_exercises.video_exercise.grouped &&
              sorted_exercises.video_exercise.grouped.length)
          "
          style="margin-bottom: 40px"
          :elevation="one_list ? 0 : 1"
        >
          <v-card-title primary-title>
            <h3
              :id="entity_type"
              class="headline"
              style="padding-top: 134px; margin-top: -128px; pointer-events: none;"
            >
              <v-icon left style="vertical-align: unset">{{
                entity_icons[entity_type]
              }}</v-icon>
              {{ sorted_exercises[entity_type].title }}
            </h3>
          </v-card-title>

          <div v-if="entity_type != 'video_exercise'">
            <v-list style="padding-bottom: 0px">
              <div
                v-for="(item, index) in sorted_exercises[entity_type].items"
                :key="index"
              >
                <v-divider />
                <v-list-item
                  three-line
                  style="cursor: pointer"
                  @click="open_dialog(item.id)"
                >
                  <v-list-item-content>
                    <v-list-item-title style="font-weight: 550">{{
                      exercise_title(item)
                    }}</v-list-item-title>
                    <v-list-item-subtitle v-if="entity_type == 'reading_text'"
                      >{{ languages[item.lang].name }} &nbsp; — &nbsp;
                      {{ $t("common.level") }} {{ item.grade }} &nbsp; — &nbsp;
                      {{ item.questions.length }}
                      {{ $t("common.questions") }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle v-else>{{
                      exercise_description(item)
                    }}</v-list-item-subtitle>

                    <v-list-item-subtitle v-if="item.shared.description">{{
                      item.shared.description
                    }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-content v-if="!small_screen">
                    <div v-if="item.shared.tags && item.shared.tags.length">
                      <v-chip
                        v-for="(tag, tag_idx) in item.shared.tags"
                        :key="item.id + '_tag_' + tag_idx"
                        small
                        class="mr-4 mb-1"
                        @click.stop="$emit('filter', tag)"
                        >{{ tag }}</v-chip
                      >

                      <v-chip
                        v-if="
                          item.shared._hidden_tags && !show_hidden_tags[item.id]
                        "
                        small
                        class="mr-4 mb-1"
                        @click.stop="show_hidden_tag(item.id)"
                        >...</v-chip
                      >

                      <template v-if="show_hidden_tags[item.id]">
                        <v-chip
                          v-for="(tag, hidden_tag_idx) in item.shared
                            ._hidden_tags"
                          :key="item.id + '_hidden_tag_' + hidden_tag_idx"
                          small
                          class="mr-4 mb-1"
                          @click.stop="$emit('filter', tag)"
                          >{{ tag }}</v-chip
                        >
                      </template>
                    </div>
                  </v-list-item-content>

                  <v-list-item-action v-if="show_attribution && !dense">
                    <v-chip
                      label
                      small
                      outlined
                      class="mt-4"
                      @click.stop="
                        $router.push('/app/shared/clinic/' + item.clinic_id)
                      "
                      >{{
                        $t("common.clinic_attribution", {
                          clinic_title: item.clinic_title,
                        })
                      }}</v-chip
                    >
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-layout
                      style="width: 120px"
                      align-end
                      align-content-end
                      justify-end
                    >
                      <v-btn
                        v-if="subscribed_content[item.id]"
                        tile
                        elevation="0"
                        class="mt-2"
                        @click.stop
                      >
                        {{ $t("community_content.added") }}
                        <v-icon right>check</v-icon>
                      </v-btn>
                      <Popper
                        v-else-if="item.clinic_id != current_clinic_id"
                        trigger="hover"
                        :delay-on-mouse-over="400"
                        :options="{ placement: 'left' }"
                      >
                        <div class="popper pa-2">
                          Add
                          <span style="font-style: italic">{{
                            exercise_title(item)
                          }}</span>
                          {{ entity_titles[item.entity_type] }} to
                          {{ current_clinic.title }}
                        </div>

                        <v-btn
                          slot="reference"
                          class="mt-2"
                          elevation="0"
                          color="primary"
                          @click.stop="add(item)"
                        >
                          {{ $t("community_content.add") }}
                          <v-icon right>fas fa-plus</v-icon>
                        </v-btn>
                      </Popper>
                    </v-layout>
                  </v-list-item-action>
                </v-list-item>

                <v-dialog
                  v-model="dialogs[item.id]"
                  :max-width="entity_type == 'file' ? 600 : 1200"
                  @click:outside="exercise_stop()"
                >
                  <div v-if="dialogs[item.id]">
                    <GridSetEdit
                      v-if="entity_type == 'grid_set'"
                      ref="grid_set_edit"
                      readonly
                      :grid_set_id="item.id"
                      :clinic_id="item.clinic_id"
                      :additional_video_exercises="additional_video_exercises"
                      @close="close_dialog(item.id)"
                    ></GridSetEdit>
                    <GridScheduleEdit
                      v-else-if="entity_type == 'grid_schedule'"
                      ref="grid_schedule_edit"
                      readonly
                      :grid_schedule_id="item.id"
                      :clinic_id="item.clinic_id"
                      :additional_video_exercises="additional_video_exercises"
                      @close="close_dialog(item.id)"
                    ></GridScheduleEdit>
                    <ReadingTextEdit
                      v-else-if="entity_type == 'reading_text'"
                      :text="item"
                      readonly
                      @close="close_dialog(item.id)"
                    ></ReadingTextEdit>
                    <ToolStaticPDF
                      v-if="entity_type == 'file'"
                      :title="item.title"
                      :description="item.description"
                      :uri="
                        file_base_url +
                        item.clinic_id +
                        '/' +
                        item.id +
                        '/1.' +
                        item.filename
                      "
                      @close="close_dialog(item.id)"
                    ></ToolStaticPDF>
                  </div>
                </v-dialog>
              </div>
            </v-list>
          </div>

          <div v-else>
            <div
              v-for="group in sorted_exercises.video_exercise.grouped"
              :key="group.group"
            >
              <v-divider />
              <v-card :elevation="one_list ? 0 : 1">
                <v-card-title primary-title>
                  <h4
                    :id="group.id"
                    class="headline"
                    style="
                      font-size: 1.3rem !important;
                      padding-top: 134px;
                      margin-top: -128px;
                      pointer-events: none;
                    "
                  >
                    <v-icon left small style="vertical-align: unset">{{
                      icons[group.group]
                    }}</v-icon>
                    {{ $t("group." + group.group) }}
                  </h4>
                </v-card-title>

                <div>
                  <v-list style="padding-bottom: 0px">
                    <v-divider />
                    <div v-for="(item, index) in group.items" :key="index">
                      <v-list-item
                        three-line
                        style="cursor: pointer"
                        @click="launch_video(item.id)"
                      >
                        <v-list-item-content>
                          <v-list-item-title style="font-weight: 600">{{
                            exercise_title(item)
                          }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            exercise_description(item)
                          }}</v-list-item-subtitle>

                          <v-list-item-subtitle
                            v-if="item.shared.description"
                            >{{ item.shared.description }}</v-list-item-subtitle
                          >
                        </v-list-item-content>

                        <v-list-item-content v-if="!small_screen">
                          <div
                            v-if="item.shared.tags && item.shared.tags.length"
                          >
                            <v-chip
                              v-for="(tag, tag_idx) in item.shared.tags"
                              :key="item.id + '_tag_' + tag_idx"
                              small
                              class="mr-4 mb-1"
                              @click.stop="$emit('filter', tag)"
                              >{{ tag }}</v-chip
                            >

                            <v-chip
                              v-if="
                                item.shared._hidden_tags &&
                                !show_hidden_tags[item.id]
                              "
                              small
                              class="mr-4 mb-1"
                              @click.stop="show_hidden_tag(item.id)"
                              >...</v-chip
                            >

                            <template v-if="show_hidden_tags[item.id]">
                              <v-chip
                                v-for="(tag, hidden_tag_idx) in item.shared
                                  ._hidden_tags"
                                :key="item.id + '_hidden_tag_' + hidden_tag_idx"
                                small
                                class="mr-4 mb-1"
                                @click.stop="$emit('filter', tag)"
                                >{{ tag }}</v-chip
                              >
                            </template>
                          </div>
                        </v-list-item-content>

                        <v-list-item-action v-if="show_attribution && !dense">
                          <v-chip
                            label
                            small
                            outlined
                            class="mt-4"
                            @click.stop="
                              $router.push(
                                '/app/shared/clinic/' + item.clinic_id
                              )
                            "
                            >{{
                              $t("common.clinic_attribution", {
                                clinic_title: item.clinic_title,
                              })
                            }}</v-chip
                          >
                        </v-list-item-action>
                        <v-list-item-action>
                          <v-layout
                            style="width: 120px"
                            align-end
                            align-content-end
                            justify-end
                          >
                            <v-btn
                              v-if="subscribed_content[item.id]"
                              tile
                              elevation="0"
                              class="mt-2"
                              @click.stop
                            >
                              {{ $t("community_content.added") }}
                              <v-icon right>check</v-icon>
                            </v-btn>
                            <Popper
                              v-else-if="item.clinic_id != current_clinic_id"
                              trigger="hover"
                              :delay-on-mouse-over="400"
                              :options="{ placement: 'left' }"
                            >
                              <div class="popper pa-2">
                                Add
                                <span style="font-style: italic">{{
                                  exercise_title(item)
                                }}</span>
                                {{ entity_titles[item.entity_type] }} to
                                {{ current_clinic.title }}
                              </div>

                              <v-btn
                                slot="reference"
                                class="mt-2"
                                elevation="0"
                                color="primary"
                                @click.stop="add(item)"
                              >
                                {{ $t("community_content.add") }}
                                <v-icon right>fas fa-plus</v-icon>
                              </v-btn>
                            </Popper>
                          </v-layout>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider />

                      <v-dialog
                        v-model="dialogs[item.id]"
                        max-width="800"
                        @click:outside="exercise_stop()"
                      >
                        <VideoExercise
                          v-if="dialogs[item.id]"
                          ref="VideoExercise"
                          :exercise="video_transform(item)"
                          show_config
                          :trusted="false"
                          @close="close_dialog(item.id)"
                          @exercise-finished="close_dialog(item.id)"
                        ></VideoExercise>
                      </v-dialog>
                    </div>
                  </v-list>
                </div>
              </v-card>
            </div>
          </div>
        </v-card>
      </div>
    </div>

    <v-dialog v-model="needed_dependencies_dialog" max-width="800">
      <v-card v-if="needed_dependencies_dialog">
        <v-card-title style="padding: 0px">
          <v-toolbar grey flat>
            <v-btn icon @click="needed_dependencies_dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{
              $t("community_content.grid_dependencies")
            }}</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="mt-4">
          <p>{{ $t("community_content.grid_dependencies_description") }}</p>

          <v-list style="padding-bottom: 0px">
            <div v-for="(dep_id, index) in needed_dependencies" :key="index">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{
                    exercise_title(shared_index[dep_id])
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    exercise_description(shared_index[dep_id])
                  }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <ExercisePreviewButton
                    :exercise="video_to_builtin(shared_index[dep_id])"
                    class="mr-5"
                  />
                </v-list-item-action>
              </v-list-item>
              <v-divider />
            </div>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn @click="needed_dependencies_dialog = false">{{
            $t("common.cancel")
          }}</v-btn>
          <v-btn
            color="primary"
            @click="
              add(needed_dependencies_entity, true);
              needed_dependencies_dialog = false;
            "
            >{{ $t("common.continue") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import $ from "jquery";
import VideoExercise from "./VideoExercise";
import GridSetEdit from "./GridSetEdit";
import GridScheduleEdit from "./GridScheduleEdit";
import ReadingTextEdit from "./ReadingTextEdit";
import ToolStaticPDF from "./ToolStaticPDF";
import ExercisePreviewButton from "./ExercisePreviewButton";
import NoResults from "./NoResults";
import { mapState } from "vuex";
import { Selection } from "../classes/selection";
import { category_icons } from "../lib/category";
import axios from "axios";
import languages from "../../../data/lang_codes.json";
import { clinic_to_builtin } from "../classes/video_exercise";
import Popper from "vue-popperjs";
import "vue-popperjs/dist/vue-popper.css";

export default {
  components: {
    VideoExercise,
    GridSetEdit,
    GridScheduleEdit,
    ReadingTextEdit,
    ToolStaticPDF,
    Popper,
    ExercisePreviewButton,
    NoResults,
  },
  props: {
    sidebar: {
      type: Boolean,
      default: () => true,
    },
    show_attribution: {
      type: Boolean,
      default: () => true,
    },
    clinic_id_filter: {
      type: String,
      default: () => "",
    },
    one_list: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      icons: category_icons,
      shared: [],
      shared_index: {},
      languages,
      entity_types: [
        "grid_set",
        "grid_schedule",
        "reading_text",
        "file",
        "video_exercise",
      ],
      dialogs: {},
      show_hidden_tags: {},
      needed_dependencies: [],
      needed_dependencies_dialog: false,
      share_dependencies_entity: null,
      in_flight: false,
      loading: true,
      filter: "",
      entity_icons: {
        grid_set: "fas fa-align-justify",
        grid_schedule: "fas fa-border-all",
        reading_text: "mdi-book-open-page-variant",
        file: "print",
        video_exercise: "video_library",
      },
      entity_titles_plural: {
        grid_set: this.$t("community_sharing.grid_sets"),
        grid_schedule: this.$t("community_sharing.grid_schedules"),
        reading_text: this.$t("community_sharing.reading_texts"),
        file: this.$t("community_sharing.printable_sheets"),
        video_exercise: this.$t("community_sharing.video_exercises"),
      },
      entity_titles: {
        grid_set: this.$t("community_sharing.grid_set"),
        grid_schedule: this.$t("community_sharing.grid_schedule"),
        reading_text: this.$t("community_sharing.reading_text"),
        file: this.$t("community_sharing.printable_sheet"),
        video_exercise: this.$t("community_sharing.video_exercise"),
      },
    };
  },
  computed: {
    ...mapState([
      "current_user",
      "current_clinic_id",
      "current_clinic",
      "current_clinic_selections",
      "current_clinic_role",
      "file_base_url",
      "video_exercises",
      "grid_sets",
      "grid_schedules",
      "reading_texts",
      "clinic_video_exercises",
      "clinic_printables",
      "small_screen",
    ]),
    mainlist_style() {
      if (
        this.sidebar &&
        this.$vuetify.breakpoint.name != "xs" &&
        this.$vuetify.breakpoint.name != "sm"
      ) {
        const direction = this.$vuetify.rtl ? "right" : "left";
        const paddingDirection = this.$vuetify.rtl
          ? "padding-left"
          : "padding-right";
        return this.dense
          ? `position: relative; ${direction}: 216px; ${paddingDirection}: 220px`
          : `position: relative; ${direction}: 266px; ${paddingDirection}: 270px`;
      } else {
        return "";
      }
    },
    dense() {
      return (
        !this.$root.$children[0].drawer_mini &&
        this.$vuetify.breakpoint.width < 1300
      );
    },
    subscribed_content() {
      let subscribed_content = {};
      for (let item of this.clinic_printables) {
        if (item.share_source && item.clinic_id == this.current_clinic_id) {
          subscribed_content[item.share_source.id] = true;
        }
      }
      for (let item of this.reading_texts) {
        if (item.share_source && item.clinic_id == this.current_clinic_id) {
          subscribed_content[item.share_source.id] = true;
        }
      }
      for (let item of this.clinic_video_exercises) {
        if (item.share_source && item.clinic_id == this.current_clinic_id) {
          subscribed_content[item.share_source.id] = true;
        }
      }
      for (let item of this.grid_sets) {
        if (item.share_source && item.clinic_id == this.current_clinic_id) {
          subscribed_content[item.share_source.id] = true;
        }
      }
      for (let item of this.grid_schedules) {
        if (item.share_source && item.clinic_id == this.current_clinic_id) {
          subscribed_content[item.share_source.id] = true;
        }
      }

      return subscribed_content;
    },
    filtered_exercises() {
      let exercises_array = [];
      for (var shared of this.shared) {
        if (
          !shared.shared.quality ||
          shared.shared.quality == "poor" ||
          shared.shared.auto_import
        ) {
          continue;
        }

        if (
          this.clinic_id_filter &&
          shared.clinic_id != this.clinic_id_filter
        ) {
          continue;
        }

        exercises_array.push(shared);
      }

      let filter = this.filter ? this.filter.trim().toLowerCase() : "";
      if (!filter) {
        return exercises_array;
      }

      let filtered = [];
      for (let exercise of exercises_array) {
        let tags = exercise.shared.tags.map((t) =>
          t.toLowerCase().replace(/\./g, "")
        );
        let hidden_tags = exercise.shared._hidden_tags
          ? exercise.shared._hidden_tags.map((t) =>
              t.toLowerCase().replace(/\./g, "")
            )
          : [];
        let title = this.exercise_title(exercise);
        let desc = this.exercise_description(exercise);
        let title_stripped = title.replace(/\./g, "");
        let exercise_group = exercise.exercise_group
          ? exercise.exercise_group.toLowerCase().replace(/\./g, "")
          : "";
        if (
          title.toLowerCase().includes(filter) ||
          desc.toLowerCase().includes(filter) ||
          title_stripped.toLowerCase().includes(filter) ||
          exercise.clinic_title.toLowerCase().includes(filter) ||
          tags.includes(filter) ||
          hidden_tags.includes(filter) ||
          exercise_group.includes(filter)
        ) {
          filtered.push(exercise);
        }
      }

      return filtered;
    },
    video_selections() {
      let selections = {};

      if (!this.current_clinic_selections) {
        return selections;
      }

      for (let builtin_select of this.current_clinic_selections.get_by_type(
        "video/builtin"
      )) {
        selections[builtin_select.entity_id] = builtin_select.selected;
      }
      for (let video_select of this.current_clinic_selections.get_by_type(
        "video_exercise"
      )) {
        selections[video_select.entity_id] = video_select.selected;
      }

      return selections;
    },

    sorted_exercises() {
      let meta_groups = {
        video_exercise: {
          title: this.$t("community_sharing.video_exercises"),
          id: "video_exercise",
          grouped: [],
        },
        grid_set: {
          title: this.$t("community_sharing.grid_sets"),
          id: "grid_set",
          items: [],
        },
        grid_schedule: {
          title: this.$t("community_sharing.grid_schedules"),
          id: "grid_schedule",
          items: [],
        },
        reading_text: {
          title: this.$t("community_sharing.reading_texts"),
          id: "reading_text",
          items: [],
        },
        file: {
          title: this.$t("community_sharing.printable_sheets"),
          id: "file",
          items: [],
        },
      };

      for (let exercise of this.filtered_exercises) {
        if (exercise.entity_type != "video_exercise") {
          meta_groups[exercise.entity_type].items.push(exercise);
        } else {
          if (!exercise.video) {
            continue;
          }
          if (!exercise.exercise_group) {
            exercise.exercise_group = "Uncategorized";
          }
          var group_exists = false;
          let group_i = 0;

          for (var i in meta_groups.video_exercise.grouped) {
            if (
              meta_groups.video_exercise.grouped[i].group ==
              exercise.exercise_group
            ) {
              group_exists = true;
              group_i = i;
            }
          }
          if (!group_exists) {
            let group = {
              group: exercise.exercise_group,
              anchor: "#" + exercise.exercise_group.replace(/\s+/g, ""),
              id: exercise.exercise_group.replace(/\s+/g, ""),
              items: [exercise],
            };
            meta_groups.video_exercise.grouped.push(group);
          } else {
            meta_groups.video_exercise.grouped[group_i].items.push(exercise);
          }
        }
      }

      // Sort the groups and exercises
      meta_groups.video_exercise.grouped.sort((a, b) => {
        if (a.group == "Uncategorized") {
          return 1;
        }
        if (b.group == "Uncategorized") {
          return -1;
        }

        return a.group > b.group ? 1 : -1;
      });

      return meta_groups;
    },
    total_count() {
      let count = 0;
      for (let group of this.sorted_exercises.video_exercise.grouped) {
        count += group.items.length;
      }
      count += this.sorted_exercises.grid_set.items.length;
      count += this.sorted_exercises.grid_schedule.items.length;
      count += this.sorted_exercises.reading_text.items.length;
      count += this.sorted_exercises.file.items.length;
      return count;
    },
    additional_video_exercises() {
      let additional_video_exercises = {};
      for (let shared of this.shared) {
        if (shared.entity_type == "video_exercise") {
          let additional_video = clinic_to_builtin(shared);
          additional_video_exercises[additional_video.id] = additional_video;
        }
      }
      return additional_video_exercises;
    },
  },
  mounted() {
    // If we don't have a current clinic set, load it
    if (
      (this.current_clinic_id && !this.current_clinic) ||
      (this.current_clinic && this.current_clinic.id != this.current_clinic_id)
    ) {
      this.$store.dispatch("loadCurrentClinic", {
        clinic_id: this.current_clinic_id,
        callback: () => {
          this.$store.dispatch("loadClinicVideoExercises");
          this.load_shared();
        },
      });
    } else {
      this.$store.dispatch("loadClinicVideoExercises");
      this.load_shared();
    }
  },
  methods: {
    add(entity, dependencies_ok = false) {
      // Check if in flight
      if (this.in_flight) {
        return;
      }

      // Check for dependencies
      if (entity.shared.dependencies.length > 0 && !dependencies_ok) {
        let needed_dependencies = entity.shared.dependencies.filter(
          (d) => !this.subscribed_content[d]
        );

        if (needed_dependencies.length > 0) {
          this.needed_dependencies = needed_dependencies;
          this.needed_dependencies_dialog = true;
          this.needed_dependencies_entity = entity;
          return;
        }
      }

      let add_request = {
        id: entity.id,
        clinic_id: this.current_clinic_id,
        entity_type: entity.entity_type,
      };

      this.in_flight = true;
      axios.post("/api/shared/add", add_request).then((response) => {
        if (response.data.status == "success") {
          // TODO: Translate this
          messageBus.$emit(
            "success",
            entity.title + " added to " + this.current_clinic.title
          );
          if (entity.entity_type == "video_exercise") {
            this.$store.dispatch("loadClinicVideoExercises", () => {
              this.in_flight = false;
            });
            this.$store.dispatch("loadVideoExercises");
          }
          if (entity.entity_type == "grid_set") {
            this.$store.dispatch("loadClinicVideoExercises");
            this.$store.dispatch("loadVideoExercises");
            this.$store.dispatch("loadGrids", () => {
              this.in_flight = false;
            });
          }
          if (entity.entity_type == "grid_schedule") {
            this.$store.dispatch("loadClinicVideoExercises");
            this.$store.dispatch("loadVideoExercises");
            this.$store.dispatch("loadGrids", () => {
              this.in_flight = false;
            });
          }
          if (entity.entity_type == "file") {
            this.$store.dispatch("loadClinicPrintables", () => {
              this.in_flight = false;
            });
          }
          if (entity.entity_type == "reading_text") {
            this.$store.dispatch("loadReadingTexts", () => {
              this.in_flight = false;
            });
          }
        } else {
          this.in_flight = false;
          window.messageBus.$emit("error", response.data.error_message);
        }
      });
    },
    show_hidden_tag(item_id) {
      Vue.set(this.show_hidden_tags, item_id, true);
    },
    load_shared() {
      this.loading = true;
      axios.get("/api/shared").then((response) => {
        if (response.data.status == "success") {
          let shared = response.data.data;

          // Mark the sort order by quality, also count total number of tags by tag

          let tag_counts = {};
          for (let idx in shared) {
            shared[idx]._sort_order = 0;
            if (shared[idx].shared.quality == "poor") {
              shared[idx]._sort_order = 1;
            }
            if (shared[idx].shared.quality == "good") {
              shared[idx]._sort_order = 2;
            }
            if (shared[idx].shared.quality == "excellent") {
              shared[idx]._sort_order = 3;
            }

            for (let tag of shared[idx].shared.tags) {
              if (!tag_counts[tag]) {
                tag_counts[tag] = 0;
              }
              tag_counts[tag]++;
            }
          }

          // Process tags
          for (let idx in shared) {
            let tags = shared[idx].shared.tags;

            // Filter out tags that are also the name of the exercise_group
            if (shared[idx].exercise_group) {
              tags = tags.filter(
                (t) =>
                  t.toLowerCase() != shared[idx].exercise_group.toLowerCase()
              );
              shared[idx].shared.tags = tags;
            }

            // For each shared item, limit the number of tags to 5, preferring the least popular tags
            tags.sort((a, b) => {
              if (tag_counts[a] > tag_counts[b]) {
                return 1;
              }
              if (tag_counts[a] < tag_counts[b]) {
                return -1;
              }
              return 0;
            });
            if (tags.length > 4) {
              shared[idx].shared._hidden_tags = tags.slice(3);
              shared[idx].shared.tags = tags.slice(0, 3);
            }
          }

          // Sort by sort order
          shared.sort((a, b) => {
            if (a._sort_order > b._sort_order) {
              return -1;
            }
            if (a._sort_order < b._sort_order) {
              return 1;
            }
            return 0;
          });

          this.shared = shared;

          for (let entity of this.shared) {
            Vue.set(this.dialogs, entity.id, false);
            Vue.set(this.shared_index, entity.id, entity);
          }
        }
        this.loading = false;
      });
    },
    exercise_title(exercise) {
      if (!exercise.title) {
        return "";
      }

      if (typeof exercise.title == "string") {
        return exercise.title;
      }

      let locale = this.$i18n.lang();
      if (exercise.title[locale]) {
        return exercise.title[locale];
      } else {
        return exercise.title.en;
      }
    },
    exercise_description(exercise) {
      if (!exercise.description) {
        return "";
      }
      if (typeof exercise.description == "string") {
        return exercise.description;
      }

      let locale = this.$i18n.lang();
      if (exercise.description[locale]) {
        return exercise.description[locale];
      } else {
        return exercise.description.en;
      }
    },
    open_dialog(exercise_id) {
      Vue.set(this.dialogs, exercise_id, true);
    },
    close_dialog(exercise_id) {
      Vue.set(this.dialogs, exercise_id, false);
      this.exercise_stop();
    },
    exercise_stop() {
      for (var i in this.$refs["VideoExercise"]) {
        this.$refs["VideoExercise"][i].stop();
      }
    },
    video_transform(video_entity) {
      return clinic_to_builtin(video_entity);
    },
    launch_video(exercise_id) {
      let self = this;
      Vue.set(this.dialogs, exercise_id, true);
      Vue.nextTick(function () {
        for (var i in self.$refs["VideoExercise"]) {
          if (self.$refs["VideoExercise"][i].exercise.id == exercise_id) {
            self.$refs["VideoExercise"][i].load();
          }
        }
      });
    },
    image_url(img_path) {
      return img_path;
    },
    toggle_enabled(item) {
      if (this.in_progress[item.id]) {
        return;
      }

      if (this.current_clinic_role !== "admin") {
        messageBus.$emit(
          "error",
          this.$t("video_demo_list.please_ask_admin_to_enable")
        );
        return;
      }

      if (item.origin == "builtin") {
        let selection = this.current_clinic_selections.get_by_entity_id(
          item.id,
          "video/builtin"
        );

        if (selection) {
          if (selection.selected) {
            selection.selected = false;
          } else {
            selection.selected = true;
          }
        } else {
          selection = Selection.fromJson({
            clinic_id: this.current_clinic_id,
            entity_id: item.id,
            entity_type: "video/builtin",
            selected: false, // By default builtins are ON, so we set it to false
          });
        }

        this.toggled.push(item.id);

        Vue.set(this.in_progress, item.id, true);
        this.$store.dispatch("upsertSelection", {
          selection,
          callback: () => {
            Vue.set(this.in_progress, item.id, false);
          },
        });
      }
    },
    video_to_builtin(item) {
      item.exercise_type = "custom"; // It's not actually a custom, but we're passing the data directly in instead of loading it via the backend, so we need to set it to custom.
      item.default = {
        video: item.video,
        variants: [],
        title: item.tile,
        subtitles: {},
      };
      return item;
    },
  },
};

window.jQuery = $;
window.$ = $;
</script>

<style scoped>
.theme--light a.shared_content_list_jump_link {
  background-color: transparent;
}

.category-list .video_exercise_group_jump_link {
  margin-left: 60px;
}

.category-list.dense .video_exercise_group_jump_link {
  margin-left: 20px;
}

.category-list .video_exercise_group_jump_link.rtl {
  margin-right: 60px;
  margin-left: 0px;
}

.category-list.dense .video_exercise_group_jump_link.rtl {
  margin-right: 20px;
  margin-left: 0px;
}
</style>
