<template>
  <v-app>
    <div v-if="admin_or_staff">
      <v-app-bar app dark flat class="page_toolbar">
        <v-toolbar-title>
          <v-icon left>mdi-share-variant</v-icon>
          {{ $t("common.community_content") }}
        </v-toolbar-title>
      </v-app-bar>
      <CommunityContent />
    </div>
    <PermissionDenied v-else />
  </v-app>
</template>

<script>
import CommunityContent from "../../shared/components/CommunityContent";
import PermissionDenied from "../../shared/components/PermissionDenied";

export default {
  name: "App",
  components: {
    CommunityContent,
    PermissionDenied,
  },
  data() {
    return {
      current_user: this.$root.$children[0].current_user,
      user_invites: this.$root.$children[0].user_invites,
    };
  },
  computed: {
    admin_or_staff() {
      if (this.current_user.is_admin) {
        return true;
      }
      for (var i in this.user_invites) {
        let invite = this.user_invites[i];
        if (invite.user_role == "admin" || invite.user_role == "staff") {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    update_search(new_search) {
      this.search = new_search;
    },
  }
};
</script>

<style>
</style>