<template>
  <v-app>
    <div v-if="admin_or_staff">
      <v-app-bar dark flat app class="page_toolbar">
        <v-toolbar-title>
          <v-icon left>category</v-icon>
          {{ $t('common.exercises') }}
        </v-toolbar-title>
      </v-app-bar>
      <CombinedExerciseDemoList
        v-if="current_user && current_clinic_id"
        :show_advanced_config="admin_or_staff"
        :show_missing_something="admin_or_staff"
      />
    </div>
    <PermissionDenied v-else />
  </v-app>
</template>

<script>
import CombinedExerciseDemoList from "../../shared/components/CombinedExerciseDemoList";
import PermissionDenied from "../../shared/components/PermissionDenied";
import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    CombinedExerciseDemoList,
    PermissionDenied,
  },
  data() {
    return {
      user_invites: this.$root.$children[0].user_invites,
    };
  },
  computed: {
    ...mapState(["current_clinic_id", "current_user"]),
    admin_or_staff() {
      if (this.current_user.is_admin) {
        return true;
      }
      for (var i in this.user_invites) {
        let invite = this.user_invites[i];
        if (invite.user_role == "admin" || invite.user_role == "staff") {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style>
</style>