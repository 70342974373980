<template>
  <div>
    <v-app-bar app flat style="margin-top: 64px; border-bottom: 1px solid rgba(0, 0, 0, 0.2)">
      <v-text-field
        v-model="filter"
        append-icon="mdi-magnify"
        :label="$t('common.search')"
        single-line
        hide-details
        outlined
        dense
        style="max-width: 500px"
      ></v-text-field>

      <v-spacer />

      <v-btn
        v-if="admin_or_staff"
        class="mr-1"
        color="primary"
        @click="create_printable_dialog = true"
      >
        {{ $t("common.create_printable_sheet") }}
      </v-btn>

      <v-dialog v-model="create_printable_dialog" max-width="1200">
        <PrintableEdit
          v-if="create_printable_dialog"
          :printable="new_printable"
          @close="close_new_printable_dialog"
          @printable-created="load_clinic_printables"
          @printable-updated="load_clinic_printables"
        ></PrintableEdit>
      </v-dialog>
    </v-app-bar>

    <div>
      <v-list class="mt-n2">
        <div v-for="(item, index) in printables" :key="item.id">
          <v-list-item
            three-line
            :style="'cursor: pointer; background-color:' + item_background_color(index)"
            @click="open_printable_dialog(item.id)"
          >
            <v-list-item-content>
              <v-list-item-title style="font-weight: 550">
                {{ item.title }}
              </v-list-item-title>
              <v-list-item-subtitle>{{
                item.description
              }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action v-if="admin_or_staff">
              <v-tooltip v-if="item.clinic_id && item.shared" top>
                <template v-slot:activator="{ on }">
                  <v-chip
                    class="ma-2 mt-4"
                    style="width: 90px"
                    small
                    close
                    label
                    outlined
                    close-icon="mdi-share-variant"
                    v-on="on"
                  >
                    {{ $t("common.shared") }}</v-chip
                  >
                </template>
                <span>{{ $t("clinic_page_manage_printables.shared_exercise") }}</span>
              </v-tooltip>
              <v-tooltip v-else-if="item.clinic_id && !item.shared" top>
                <template v-slot:activator="{ on }">
                  <v-chip
                    class="ma-2 mt-4"
                    style="width: 90px"
                    small
                    label
                    outlined
                    v-on="on"
                  >
                    {{ $t("video_demo_list.private") }}
                    <v-icon right x-small style="margin-left: 15px"
                      >fa fa-user-lock</v-icon
                    ></v-chip
                  >
                </template>
                <span>{{ $t("clinic_page_manage_printables.private_sheet") }}</span>
              </v-tooltip>
              <v-tooltip v-else-if="item.share_source" top>
                <template v-slot:activator="{ on }">
                  <v-chip label small outlined class="mt-4" v-on="on">{{
                    $t("common.clinic_attribution", {
                      clinic_title: item.provider_name,
                    })
                  }}</v-chip>
                </template>
                <span>{{ $t("clinic_page_manage_printables.private_sheet") }}</span>
              </v-tooltip>
            </v-list-item-action>
            <v-list-item-action v-if="admin_or_staff">
              <div v-if="item.clinic_id">
                <v-chip
                  class="ma-2 mt-3"
                  style="width: 90px; cursor: pointer"
                  label
                  outlined
                  color="primary"
                  v-on="on"
                  @click.stop="open_edit_dialog(item.id)"
                >
                  <v-icon left x-small>fa fa-edit</v-icon>
                  {{ $t("common.edit") }}
                </v-chip>

                <v-dialog
                  v-model="edit_printable_dialogs[item.id]"
                  max-width="1200"
                >
                  <PrintableEdit
                    v-if="edit_printable_dialogs[item.id]"
                    :printable="item"
                    @close="load_clinic_printables"
                    @printable-created="load_clinic_printables"
                    @printable-updated="load_clinic_printables"
                    @printable-deleted="load_clinic_printables"
                  ></PrintableEdit>
                </v-dialog>
              </div>
            </v-list-item-action>
          </v-list-item>
          <v-divider />
          <v-dialog v-model="printable_dialogs[item.id]" max-width="600">
            <template v-if="printable_dialogs[item.id] && item.type == 'builtin'">
              <ToolHartChart
                v-if="item.id == 'hart_chart'"
                @close="printable_dialogs[item.id] = false"
              />
              <ToolRacetracks
                v-if="item.id == 'racetracks'"
                @close="printable_dialogs[item.id] = false"
              />
              <ToolTrackLetters
                v-if="item.id == 'saccadic_scanning'"
                @close="printable_dialogs[item.id] = false"
              />
              <ToolAnaglyphChart
                v-if="item.id == 'anaglyph_chart'"
                @close="printable_dialogs[item.id] = false"
              />
              <ToolFusionCard
                v-if="item.id == 'fusion_card'"
                @close="printable_dialogs[item.id] = false"
              />
              <ToolSlapTap
                v-if="item.id == 'slap_tap'"
                @close="printable_dialogs[item.id] = false"
              />
              <ToolCircleSquare
                v-if="item.id == 'circle_square'"
                @close="printable_dialogs[item.id] = false"
              />
              <ToolStaticPDF
                v-if="item.id == 'rotating_forms'"
                uri="https://storage.googleapis.com/opticalgym-large-assets/pdfs/static/rotating_forms.pdf"
                :title="item.title"
                @close="printable_dialogs[item.id] = false"
              />

              <ToolCircleLineJump
                v-if="item.id == 'circle_jump'"
                @close="printable_dialogs[item.id] = false"
              />

              <ToolCircleTriangle
                v-if="item.id == 'circle_triangle'"
                @close="printable_dialogs[item.id] = false"
              />

              <ToolStaticPDF
                v-if="item.id == 'arrows'"
                uri="/api/tools/pdf/picture_grid/arrows/picture_grid.pdf?rows=8&cols=6&size=80&padding_sides=20&padding_tottom=20"
                :title="item.title"
                @close="printable_dialogs[item.id] = false"
              />
              <ToolStaticPDF
                v-if="item.id == 'finger_counting'"
                uri="/api/tools/pdf/picture_grid/finger_counting/picture_grid.pdf?rows=8&cols=6&size=90&padding_sides=20&padding_tottom=20"
                :title="item.title"
                @close="printable_dialogs[item.id] = false"
              />
              <ToolStaticPDF
                v-if="item.id == 'happy_faces'"
                uri="/api/tools/pdf/picture_grid/happy_faces/picture_grid.pdf?rows=16&cols=12&size=48&padding_sides=8&padding_tottom=6"
                :title="item.title"
                @close="printable_dialogs[item.id] = false"
              />
              <ToolPeripheralChart
                v-if="item.id == 'peripheral_chart'"
                @close="printable_dialogs[item.id] = false"
              />
              <ToolLadyBug
                v-if="item.id == 'lady_bug_fixation'"
                @close="printable_dialogs[item.id] = false"
              />
              <ToolPerceptualSpeed
                v-if="item.id == 'perceptual_speed'"
                @close="printable_dialogs[item.id] = false"
              />
              <ToolLineMaze
                v-if="item.id == 'line_maze'"
                @close="printable_dialogs[item.id] = false"
              />
            </template>
            <ToolStaticPDF
              v-else
              :uri="
                item.type == 'clinic'
                  ? clinic_printable_uri(item)
                  : item.uri
              "
              :title="item.title"
              :description="item.description"
              @close="printable_dialogs[item.id] = false"
            />
          </v-dialog>
        </div>
      </v-list>

      <NoResults v-if="filter && !printables.length" :query="filter" class="mt-12" /> 

      <v-banner
        v-if="admin_or_staff && show_missing_something"
        two-line
        elevation="2"
        style="margin-top: 40px; margin: auto;"
        class="mt-12"
        width="800px"
        icon="contact_support"
      >
        <p>{{ $t("common.missing_something") }}</p>
        <p style="font-size: smaller">{{ $t("common.contact_us_text") }}</p>

        <template v-slot:actions>
          <v-btn
            text
            color="primary accent-4"
            style="position: relative; top: -20px"
            @click="contact_us_dialog = true"
            >{{ $t("common.contact_us") }}</v-btn
          >
        </template>

        <v-dialog v-model="contact_us_dialog" max-width="600px">
          <ContactUs v-if="contact_us_dialog" @close="contact_us_dialog = false" />
        </v-dialog>
      </v-banner>
    </div>

    
  </div>
</template>

<script>
import ToolAnaglyphChart from "./ToolAnaglyphChart";
import ToolCircleTriangle from "./ToolCircleTriangle";
import ToolFusionCard from "./ToolFusionCard";
import ToolHartChart from "./ToolHartChart";
import ToolLadyBug from "./ToolLadyBug";
import ToolPeripheralChart from "./ToolPeripheralChart";
import ToolRacetracks from "./ToolRacetracks";
import ToolSlapTap from "./ToolSlapTap";
import ToolStaticPDF from "./ToolStaticPDF";
import ToolTrackLetters from "./ToolTrackLetters";
import ToolCircleSquare from "./ToolCircleSquare";
import ToolCircleLineJump from "./ToolCircleLineJump";
import ToolPerceptualSpeed from "./ToolPerceptualSpeed";
import ToolLineMaze from "./ToolLineMaze";
import NoResults from "./NoResults";

import ContactUs from "./ContactUs";
import PrintableEdit from "./PrintableEdit";
import Vue from "vue";
import { mapState } from "vuex";

export default {
  components: {
    ToolHartChart,
    ToolSlapTap,
    ToolTrackLetters,
    ToolRacetracks,
    ToolAnaglyphChart,
    ToolStaticPDF,
    ToolLadyBug,
    ToolCircleTriangle,
    ContactUs,
    ToolFusionCard,
    ToolPeripheralChart,
    ToolCircleSquare,
    ToolCircleLineJump,
    ToolPerceptualSpeed,
    ToolLineMaze,
    PrintableEdit,
    NoResults,
  },
  props: {
    clinic_id: {
      type: String,
      required: true,
    },
    admin_or_staff: {
      type: Boolean,
      default: false,
    },
    show_missing_something: {
      type: Boolean,
      default: () => true,
    },
    apps: {
      type: Array,
      default: () => ["metronome", "stopwatch", "full_colour_anaglyph"],
    },
  },
  data() {
    return {
      rows_per_page: [-1],
      contact_us_dialog: false,
      printable_dialogs: {},
      create_printable_dialog: false,
      edit_printable_dialogs: {},
      on: null,
      new_printable: {
        title: "",
        description: "",
        category: "printable",
        mime: "application/pdf",
        public: false,
        filename: "",
        tools: [],
        clinic_id: this.clinic_id,
      },
      filter: "",
      builtin_printables: [
        {
          id: "hart_chart",
          title: this.$t("common.hart_chart"),
          description: this.$t("tools.description_hart_chart"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "saccadic_scanning",
          title: this.$t("common.saccadic_scanning"),
          description: this.$t("tools.description_saccadic_scanning"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "racetracks",
          title: this.$t("common.racetracks"),
          description: this.$t("tools.description_racetracks"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "anaglyph_chart",
          title: this.$t("common.anaglyph_charts"),
          description: this.$t("tools.description_anaglyph_charts"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "fusion_card",
          title: this.$t("common.fusion_card"),
          description: "",
          dialog: false,
          type: "builtin",
        },
        {
          id: "slap_tap",
          title: this.$t("common.slap_tap"),
          description: this.$t("tools.description_slap_tap"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "circle_square",
          title: this.$t("common.circle_square"),
          description: this.$t("tools.description_circle_square"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "rotating_forms",
          title: this.$t("common.rotating_forms"),
          description: this.$t("tools.description_rotating_forms"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "circle_jump",
          title: this.$t("common.circle_line_jump"),
          description: this.$t("tools.description_circle_line_jump"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "circle_triangle",
          title: this.$t("common.circle_triangles"),
          description: this.$t("tools.description_circle_triangles"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "arrows",
          title: this.$t("common.directional_arrow_chart"),
          description: this.$t("tools.description_directional_arrow_chart"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "happy_faces",
          title: this.$t("common.happy_faces_chart"),
          description: this.$t("tools.description_happy_faces_chart"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "finger_counting",
          title: this.$t("common.fingers_chart"),
          description: this.$t("tools.description_fingers_chart"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "lady_bug_fixation",
          title: this.$t("common.ladybug_fixation"),
          description: this.$t("tools.description_lady_bug_fixation"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "peripheral_chart",
          title: this.$t("common.peripheral_chart"),
          description: this.$t("tools.peripheral_chart_description"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "perceptual_speed",
          title: this.$t("common.perceptual_speed"),
          description: this.$t("tools.perceptual_speed_description"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "line_maze",
          title: this.$t("common.line_maze"),
          description: this.$t("tools.line_maze_description"),
          dialog: false,
          type: "builtin",
        }
      ],
    };
  },
  computed: {
    ...mapState(["clinic_printables", "file_base_url", "current_clinic_role"]),
    printables() {
      let printables = this.builtin_printables;
      printables = printables.concat(this.clinic_printables);

      // Filter by search
      if (this.filter.trim()) {
        printables = printables.filter((item) => {
          return (
            item.title.toLowerCase().includes(this.filter.toLowerCase()) ||
            item.description.toLowerCase().includes(this.filter.toLowerCase())
          );
        });
      }

      // Sort by title
      printables.sort((a, b) => (a.title > b.title ? 1 : -1));
      return printables;
    },
    is_small_screen() {
      return (
        this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "sm"
      );
    },
  },
  mounted() {
    this.load_clinic_printables();

    // Set dialogs for built-ins
    for (var i in this.builtin_printables) {
      let printable = this.builtin_printables[i];
      Vue.set(this.printable_dialogs, printable.id, false);
    }
  },
  methods: {
    item_background_color(index) {
      if (this.$vuetify.theme.dark) {
        return index % 2 == 0 ? "" : "rgba(255, 255, 255, 0.04)";
      } else {
        return index % 2 == 0 ? "" : "rgba(0, 0, 0, 0.04)";
      }
    },
    open_printable_dialog(item_id) {
      Vue.set(this.printable_dialogs, item_id, true);
    },
    open_edit_dialog(item_id) {
      Vue.set(this.edit_printable_dialogs, item_id, true);
    },
    close_new_printable_dialog() {
      this.create_printable_dialog = false;
      this.new_printable = {
        title: "",
        description: "",
        category: "printable",
        mime: "application/pdf",
        public: false,
        filename: "",
        tools: [],
        clinic_id: this.clinic_id,
      };
    },
    load_clinic_printables() {
      if (this.clinic_id) {
        this.loading = true;
        this.$store.dispatch("loadClinicPrintables", () => {
          for (var i = 0; i < this.clinic_printables.length; i++) {
            let file = this.clinic_printables[i];
            Vue.set(this.printable_dialogs, file.id, false);
            Vue.set(this.edit_printable_dialogs, file.id, false);
            this.clinic_printables[i].type = "clinic";
          }
        });
      }
    },
    clinic_printable_uri(file) {
      return (
        this.file_base_url +
        file.clinic_id +
        "/" +
        file.id +
        "/1." +
        file.filename
      );
    },
  },
};
</script>

<style scoped>
.theme--light a.tools_jump_link {
  background-color: transparent;
}
</style>
