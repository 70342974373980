<template>
  <v-app>
    <v-app-bar app style="max-height: 65px" dark flat class="page_toolbar">
      <v-toolbar-title>
        <v-icon left>print</v-icon>{{ $t("common.printable_sheets") }}
      </v-toolbar-title>
    </v-app-bar>
    <PrintableSheetsDemoList
      v-if="current_clinic_id"
      :clinic_id="current_clinic_id"
      :admin_or_staff="admin_or_staff"
    />
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import PrintableSheetsDemoList from "../../shared/components/PrintableSheetsDemoList";

export default {
  name: "App",
  components: {
    PrintableSheetsDemoList,
  },
  data() {
    return {
      current_user: this.$root.$children[0].current_user,
      user_invites: this.$root.$children[0].user_invites,
    };
  },
  computed: {
    ...mapState(["current_clinic_id"]),
    admin_or_staff() {
      if (this.current_user.is_admin) {
        return true;
      }
      for (var i in this.user_invites) {
        let invite = this.user_invites[i];
        if (invite.user_role == "admin" || invite.user_role == "staff") {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style></style>
